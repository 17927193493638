<template>
  <div class="card w-100 h-100" style="overflow-y: scroll">
    <b-modal
      id="file_upload_modal"
      ref="file_upload_modal"
      hide-footer
      title="The following fields are not compulsary, You can click Upload to finish the File Upload."
      size="lg"
    >
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleFileUpload">
          <b-form-group label="Description">
            <validation-provider #default="{ errors }" name="description">
              <b-form-input
                id="description"
                v-model="modal.description"
                :state="errors.length > 0 ? false : null"
                name="description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Control">
            <validation-provider #default="{ errors }" name="control">
              <b-form-select
                v-model="modal.controlId"
                :options="controlOptions"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button type="submit" variant="success" :disabled="invalid">
            <feather-icon icon="UploadIcon" class="mr-50" />
            Upload
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-sidebar
      ref="sidebar-bulkupload"
      lazy
      id="sidebar-bulkupload"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      v-model="sidebar_bulkupload"
      class="p-1"
    >
      <div class="p-2">
        <validation-observer ref="UploadForm" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleFileUpload">
            <b-form-group label="Description">
              <validation-provider #default="{ errors }" name="description">
                <b-form-input
                  id="description"
                  v-model="modal.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Link Control">
              <controlTree :cf="controls.control_families" @val="getVal" />
            </b-form-group>

            <b-button type="submit" variant="success" :disabled="invalid">
              <feather-icon icon="UploadIcon" class="mr-50" />
              Upload
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-sidebar
      ref="sidebar-bulkupdate"
      lazy
      id="sidebar-bulkupdate"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      v-model="sidebar_bulkupdate"
      class="p-1"
    >
      <div class="p-2">
        <validation-observer ref="UploadForm" #default="{ invalid }">
          <b-form class="mt-2" @submit.prevent="updateBulkUpload">
            <b-form-group label="Description">
              <validation-provider #default="{ errors }" name="description">
                <b-form-input
                  id="description"
                  v-model="update_description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Link Control">
              <controlTree
                :cf="controls.control_families"
                @val="getVal"
                :selectedcontrol="controlids"
              />
            </b-form-group>

            <b-button type="submit" variant="success" :disabled="invalid">
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>

    <div class="card-body" style="padding-bottom: 0">
      <h2 class="card-title">Bulk Upload Evidence Files</h2>
      <p class="card-subtitle">
        Upload documents and attachments for assessment
      </p>

      <div
        style="height: 180px"
        class="bg-light-primary rounded border border-primary w-100 mt-1 d-flex justify-content-center align-items-center"
      >
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <feather-icon icon="UploadCloudIcon" class="text-primary" size="24" />
          <input
            type="file"
            multiple
            :name="uploadFieldName"
            :disabled="isSaving"
            @change="
              filesChange($event.target.name, $event.target.files);
              fileCount = $event.target.files.length;
            "
            accept="*"
            class="input-file"
          />
          <p class="drag-area-text" v-if="isInitial">
            Drag your file(s) here to begin<br />
            or click to browse
          </p>
          <p class="drag-area-text" v-if="isSaving">
            Uploading {{ fileCount }} files...
          </p>
        </div>
      </div>

      <b-tabs class="mt-1">
        <b-tab title="Bulk Uploaded Files">
          <div class="card mt-0">
            <table role="table" class="table b-table">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Control No.</th>
                  <th role="columnheader" scope="col">File Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Uploaded By</th>
                  <th role="columnheader" scope="col">Action</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  :key="evidence.file_id"
                  v-for="evidence in uploadedEvidences"
                >
                  <td>
                    <span
                      v-for="control in evidence.controls"
                      :key="control._id"
                      ><b-badge variant="light-primary">{{
                        control.control_no
                      }}</b-badge></span
                    >
                  </td>
                  <td role="cell" style="padding-left: 0; width: 300px">
                    <div
                      class="d-flex justify-content-start"
                      style="min-width: 300px"
                    >
                      <b-badge
                        variant="light-primary"
                        style="position: relative; overflow: hidden"
                        class="d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer"
                        v-b-tooltip.hover
                        :title="'Download: ' + evidence.original_file_name"
                        @click="
                          handleDownloadButtonClick(
                            evidence.original_file_name,
                            evidence.file_id
                          )
                        "
                      >
                        <div
                          style="width: 27px"
                          class="h-100 d-flex align-items-center justify-content-center"
                        >
                          <feather-icon
                            v-if="!downloadingList.includes(evidence.file_id)"
                            icon="DownloadIcon"
                            class="mr-75"
                            size="27"
                          />
                          <b-spinner
                            v-else
                            class="ml-0 mr-75"
                            variant="primary"
                            small
                          />
                        </div>
                        <p class="truncate text-left mb-0">
                          {{ evidence.original_file_name }}
                        </p>
                      </b-badge>
                    </div>
                  </td>

                  <td role="cell" style="padding-right: 0; width: 300px">
                    <div
                      style="width: 300px"
                      class="d-flex justify-content-start"
                    >
                      <p class="font-weight-normal mb-0">
                        {{ evidence.description }}
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="padding-right: 0">
                    <p
                      v-if="
                        evidence.uploaded_user &&
                        evidence.uploaded_user !== null
                      "
                      class="mb-0 font-weight-bold"
                    >
                      {{ evidence.uploaded_user.firstname }}
                      {{ evidence.uploaded_user.lastname }}
                    </p>
                    <b-badge v-else variant="danger">Deleted User</b-badge>
                  </td>

                  <td>
                    <feather-icon
                      icon="Edit2Icon"
                      size="18"
                      class="cursor-pointer"
                      @click="openEditBulkUpload(evidence)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Uploaded Evidence Files">
          <table role="table" class="table b-table">
            <thead role="rowgroup">
              <tr role="row">
                <th role="columnheader" scope="col">Control No.</th>
                <th role="columnheader" scope="col">File Name</th>

                <th role="columnheader" scope="col">Uploaded By</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <tr :key="file.file_id" v-for="file in getAllfiles">
                <td>
                  <span v-if="file.subcontrol && file.subcontrol != null">
                    <span>
                      <b-badge variant="light-primary">{{
                        file.subcontrol.control_no
                      }}</b-badge></span
                    >
                  </span>

                  <span v-else-if="file.control">
                    <span
                      ><b-badge variant="light-primary">{{
                        file.control.control_no
                      }}</b-badge></span
                    >
                  </span>

                  <!-- <span v-else>
                    <span v-for="control in file.control" :key="control._id"
                      ><b-badge variant="light-primary">{{
                        control.control_no
                      }}</b-badge></span
                    >
                  </span> -->
                </td>
                <td role="cell" style="padding-left: 0; width: 300px">
                  <div
                    class="d-flex justify-content-start"
                    style="min-width: 300px"
                  >
                    <b-badge
                      variant="light-primary"
                      style="position: relative; overflow: hidden"
                      class="d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer"
                      v-b-tooltip.hover
                      :title="'Download: ' + file.original_file_name"
                      @click="
                        handleDownloadButtonClick(
                          file.original_file_name,
                          file.file_id
                        )
                      "
                    >
                      <div
                        style="width: 27px"
                        class="h-100 d-flex align-items-center justify-content-center"
                      >
                        <feather-icon
                          v-if="!downloadingList.includes(file.file_id)"
                          icon="DownloadIcon"
                          class="mr-75"
                          size="27"
                        />
                        <b-spinner
                          v-else
                          class="ml-0 mr-75"
                          variant="primary"
                          small
                        />
                      </div>
                      <p class="truncate text-left mb-0">
                        {{ file.original_file_name }}
                      </p>
                    </b-badge>
                  </div>
                </td>

                <td aria-colindex="2" role="cell" style="padding-right: 0">
                  <p
                    v-if="file.uploaded_user && file.uploaded_user !== null"
                    class="mb-0 font-weight-bold"
                  >
                    {{ file.uploaded_user.firstname }}
                    {{ file.uploaded_user.lastname }}
                  </p>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BFormFile,
  BBadge,
  BSpinner,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BSidebar,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import controlTree from "./controlTree.vue";
import vSelect from "vue-select";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  components: {
    BFormFile,
    BBadge,
    BSpinner,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BFormCheckbox,
    BTabs,
    BTab,
    vSelect,
    controlTree,
  },
  mixins: [ResponseMixins, TaskMixins, UtilsMixins],

  data() {
    return {
      modal: {
        description: null,
        controlId: null,
      },
      controlOptions: [{ text: "--Select Control--", value: null }],
      selectedFile: null,
      uploadedFiles: [],
      downloadingList: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "evidences",
      sidebar_bulkupload: false,
      controlids: [],
      sidebar_bulkupdate: false,
      update_description: "",
      fileid: "",
      getAllfiles: [],
      searchcontrol: "",
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    filteredList() {
      return this.controls.control_families.filter((cf) => {
        return cf.name.toLowerCase().includes(this.searchcontrol.toLowerCase());
      });
    },
  },
  props: {
    uploadedEvidences: {
      type: Array,
      required: true,
    },

    gapAssessmentEvidenceBulkUpload: {
      type: Function,
      required: true,
    },

    reloadParent: {
      type: Function,
      required: true,
    },

    gapAssessmentId: {
      type: String,
      required: true,
    },

    refetchEvidences: {
      type: Function,
      required: true,
    },
    controls: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.reset();
    this.getAllevidenceFiles();
  },
  methods: {
    getVal(value) {
      // console.log(value);
      this.controlids = value;
      console.log(this.control_ids);
    },
    getAllevidenceFiles() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/gap-assessments/${this.gapAssessmentId}/all-evidences`,
      };
      this.$http(options)
        .then((res) => {
          this.getAllfiles = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateBulkUpload() {
      const data = {
        description: this.update_description,
        control_ids: this.controlids,
        uploaded_file_id: this.fileid,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/gap-assessments/${this.gapAssessmentId}/evidence-bulk-upload-meta-update`,
      };
      this.$http(options)
        .then((res) => {
          this.sidebar_bulkupdate = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openEditBulkUpload(evidence) {
      console.log(evidence);
      this.update_description = evidence.description;
      this.controlids = evidence.control_ids;
      this.sidebar_bulkupdate = true;
      this.fileid = evidence.file_id;
    },
    reset() {
      this.refetchEvidences(this.gapAssessmentId);
      // this.setControlOptions();
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    handleFileUpload() {
      this.save(this.selectedFile);
      this.hideModal();
    },

    setControlOptions() {
      this.controls.map((control) => {
        this.controlOptions.push({ text: control.name, value: control._id });
      });
    },

    save(files) {
      this.currentStatus = STATUS_SAVING;
      this.gapAssessmentEvidenceBulkUpload({
        gapAssessmentId: this.gapAssessmentId,
        evidenceFiles: files,
        description: this.modal.description,
        control_ids: this.controlids,
      })
        .then((res) => {
          this.handleResponse(res);
          this.currentStatus = STATUS_INITIAL;
          this.refetchEvidences(this.gapAssessmentId);
        })
        .catch((err) => {
          this.handleError(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_INITIAL;
        })
        .finally(() => {
          this.selectedFile = null;
          this.modal.description = null;
          this.modal.controlId = null;
          this.controlOptions = [{ text: "--Select Control--", value: null }];
        });
    },
    showModal() {
      // this.$refs["file_upload_modal"].show();
      this.sidebar_bulkupload = true;
      // this.controlids = [];
    },
    hideModal() {
      this.sidebar_bulkupload = false;
    },

    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      this.showModal();
      this.selectedFile = fileList;
      // this.save(fileList);
    },

    handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-custom-scrollbar {
  position: relative;
  height: 60vh;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.drag-area-text {
  text-align: center;
  padding: 16px 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
</style>
