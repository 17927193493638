var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card w-100 h-100",staticStyle:{"overflow-y":"scroll"}},[_c('b-modal',{ref:"file_upload_modal",attrs:{"id":"file_upload_modal","hide-footer":"","title":"The following fields are not compulsary, You can click Upload to finish the File Upload.","size":"lg"}},[_c('validation-observer',{ref:"UploadForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleFileUpload.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description"},model:{value:(_vm.modal.description),callback:function ($$v) {_vm.$set(_vm.modal, "description", $$v)},expression:"modal.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Control"}},[_c('validation-provider',{attrs:{"name":"control"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.controlOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.modal.controlId),callback:function ($$v) {_vm.$set(_vm.modal, "controlId", $$v)},expression:"modal.controlId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_vm._v(" Upload ")],1)],1)]}}])})],1),_c('b-sidebar',{ref:"sidebar-bulkupload",staticClass:"p-1",attrs:{"lazy":"","id":"sidebar-bulkupload","sidebar-class":"sidebar-lg","bg-variant":"white","backdrop":"","right":"","no-header":"","shadow":""},model:{value:(_vm.sidebar_bulkupload),callback:function ($$v) {_vm.sidebar_bulkupload=$$v},expression:"sidebar_bulkupload"}},[_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"UploadForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleFileUpload.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description"},model:{value:(_vm.modal.description),callback:function ($$v) {_vm.$set(_vm.modal, "description", $$v)},expression:"modal.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Link Control"}},[_c('controlTree',{attrs:{"cf":_vm.controls.control_families},on:{"val":_vm.getVal}})],1),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_vm._v(" Upload ")],1)],1)]}}])})],1)]),_c('b-sidebar',{ref:"sidebar-bulkupdate",staticClass:"p-1",attrs:{"lazy":"","id":"sidebar-bulkupdate","sidebar-class":"sidebar-lg","bg-variant":"white","backdrop":"","right":"","no-header":"","shadow":""},model:{value:(_vm.sidebar_bulkupdate),callback:function ($$v) {_vm.sidebar_bulkupdate=$$v},expression:"sidebar_bulkupdate"}},[_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"UploadForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateBulkUpload.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description"},model:{value:(_vm.update_description),callback:function ($$v) {_vm.update_description=$$v},expression:"update_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Link Control"}},[_c('controlTree',{attrs:{"cf":_vm.controls.control_families,"selectedcontrol":_vm.controlids},on:{"val":_vm.getVal}})],1),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid}},[_vm._v(" Update ")])],1)]}}])})],1)]),_c('div',{staticClass:"card-body",staticStyle:{"padding-bottom":"0"}},[_c('h2',{staticClass:"card-title"},[_vm._v("Bulk Upload Evidence Files")]),_c('p',{staticClass:"card-subtitle"},[_vm._v(" Upload documents and attachments for assessment ")]),_c('div',{staticClass:"bg-light-primary rounded border border-primary w-100 mt-1 d-flex justify-content-center align-items-center",staticStyle:{"height":"180px"}},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"UploadCloudIcon","size":"24"}}),_c('input',{staticClass:"input-file",attrs:{"type":"file","multiple":"","name":_vm.uploadFieldName,"disabled":_vm.isSaving,"accept":"*"},on:{"change":function($event){_vm.filesChange($event.target.name, $event.target.files);
            _vm.fileCount = $event.target.files.length;}}}),(_vm.isInitial)?_c('p',{staticClass:"drag-area-text"},[_vm._v(" Drag your file(s) here to begin"),_c('br'),_vm._v(" or click to browse ")]):_vm._e(),(_vm.isSaving)?_c('p',{staticClass:"drag-area-text"},[_vm._v(" Uploading "+_vm._s(_vm.fileCount)+" files... ")]):_vm._e()],1)]),_c('b-tabs',{staticClass:"mt-1"},[_c('b-tab',{attrs:{"title":"Bulk Uploaded Files"}},[_c('div',{staticClass:"card mt-0"},[_c('table',{staticClass:"table b-table",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Control No.")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("File Name")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Description")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Uploaded By")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Action")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},_vm._l((_vm.uploadedEvidences),function(evidence){return _c('tr',{key:evidence.file_id},[_c('td',_vm._l((evidence.controls),function(control){return _c('span',{key:control._id},[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(control.control_no))])],1)}),0),_c('td',{staticStyle:{"padding-left":"0","width":"300px"},attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex justify-content-start",staticStyle:{"min-width":"300px"}},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer",staticStyle:{"position":"relative","overflow":"hidden"},attrs:{"variant":"light-primary","title":'Download: ' + evidence.original_file_name},on:{"click":function($event){return _vm.handleDownloadButtonClick(
                          evidence.original_file_name,
                          evidence.file_id
                        )}}},[_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center",staticStyle:{"width":"27px"}},[(!_vm.downloadingList.includes(evidence.file_id))?_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"DownloadIcon","size":"27"}}):_c('b-spinner',{staticClass:"ml-0 mr-75",attrs:{"variant":"primary","small":""}})],1),_c('p',{staticClass:"truncate text-left mb-0"},[_vm._v(" "+_vm._s(evidence.original_file_name)+" ")])])],1)]),_c('td',{staticStyle:{"padding-right":"0","width":"300px"},attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex justify-content-start",staticStyle:{"width":"300px"}},[_c('p',{staticClass:"font-weight-normal mb-0"},[_vm._v(" "+_vm._s(evidence.description)+" ")])])]),_c('td',{staticStyle:{"padding-right":"0"},attrs:{"aria-colindex":"2","role":"cell"}},[(
                      evidence.uploaded_user &&
                      evidence.uploaded_user !== null
                    )?_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(evidence.uploaded_user.firstname)+" "+_vm._s(evidence.uploaded_user.lastname)+" ")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Deleted User")])],1),_c('td',[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"Edit2Icon","size":"18"},on:{"click":function($event){return _vm.openEditBulkUpload(evidence)}}})],1)])}),0)])])]),_c('b-tab',{attrs:{"title":"Uploaded Evidence Files"}},[_c('table',{staticClass:"table b-table",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Control No.")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("File Name")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Uploaded By")])])]),_c('tbody',{attrs:{"role":"rowgroup"}},_vm._l((_vm.getAllfiles),function(file){return _c('tr',{key:file.file_id},[_c('td',[(file.subcontrol && file.subcontrol != null)?_c('span',[_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(file.subcontrol.control_no))])],1)]):(file.control)?_c('span',[_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(file.control.control_no))])],1)]):_vm._e()]),_c('td',{staticStyle:{"padding-left":"0","width":"300px"},attrs:{"role":"cell"}},[_c('div',{staticClass:"d-flex justify-content-start",staticStyle:{"min-width":"300px"}},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer",staticStyle:{"position":"relative","overflow":"hidden"},attrs:{"variant":"light-primary","title":'Download: ' + file.original_file_name},on:{"click":function($event){return _vm.handleDownloadButtonClick(
                        file.original_file_name,
                        file.file_id
                      )}}},[_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center",staticStyle:{"width":"27px"}},[(!_vm.downloadingList.includes(file.file_id))?_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"DownloadIcon","size":"27"}}):_c('b-spinner',{staticClass:"ml-0 mr-75",attrs:{"variant":"primary","small":""}})],1),_c('p',{staticClass:"truncate text-left mb-0"},[_vm._v(" "+_vm._s(file.original_file_name)+" ")])])],1)]),_c('td',{staticStyle:{"padding-right":"0"},attrs:{"aria-colindex":"2","role":"cell"}},[(file.uploaded_user && file.uploaded_user !== null)?_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(" "+_vm._s(file.uploaded_user.firstname)+" "+_vm._s(file.uploaded_user.lastname)+" ")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Deleted User")])],1)])}),0)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }