<template>
  <div>
    <b-sidebar
      v-if="selectedResponse !== null && selectedGap.gapAssessmentId !== null"
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <gap-question-assess-form
        :gapAssessmentId="selectedGap.gapAssessmentId"
        :response="selectedResponse"
        :standard="standard"
        :gap="gap"
        :closeSidebar="closeSidebar"
        :assessedUserId="gap.assessed_by"
        :updateGapAssessmentResponsesFromResponse="
          updateGapAssessmentResponsesFromResponse
        "
        :selectedGap="selectedGap"
        :reloadParent="load"
        :handleFamilyClick="
          () => {
            if (activeGroupId !== null) {
              handleGroupClick(activeFamilyId, activeGroupId);
            } else {
              handleFamilyClick(activeFamilyId);
            }
          }
        "
      />
    </b-sidebar>
    <b-overlay
      :show="show || showOverlay"
      opacity="0.25"
      blur="1rem"
      rounded="md"
      variant="secondary"
    >
      <div class="todo-application" style="height: 80vh">
        <div class="content-area-wrapper" style="height: 100%">
          <div
            class="sidebar-left"
            style="min-width: 25%; position: relative; height: 100%"
          >
            <div
              class="sidebar"
              style="width: 100%; position: relative; height: 100%"
            >
              <div
                style="width: 100%; position: relative; height: 100%"
                class="sidebar-content todo-sidebar"
              >
                <div
                  class="todo-app-menu d-flex flex-column align-items-start justify-content-between"
                  style="height: 100%; position: relative"
                >
                  <div
                    class="w-100 d-flex flex-column justify-content-start align-items-center"
                    style="height: 90%"
                  >
                    <div
                      class="add-task border-bottom shadow-sm w-100 pb-50 pt-50"
                    >
                      <h2
                        v-if="standard !== null"
                        class="font-weight-bold text-truncate mb-0"
                        v-b-tooltip.hover.top.v-secondary
                        :title="standard.name"
                      >
                        {{ standard.name }}
                      </h2>
                      <div
                        class="d-flex flex-column align-items-start justify-content-start w-100"
                      ></div>
                      <p
                        v-if="gap"
                        class="mb-0 font-weight-bold"
                        :class="
                          gapHeaderDetailsExpanded ? null : 'text-truncate'
                        "
                        v-b-tooltip.hover.top.v-secondary
                        :title="gap.title"
                      >
                        <!-- <feather-icon
                          v-if="
                            gap.notes && gap.notes != null && gap.notes != ''
                          "
                          v-b-toggle.collapse_notes
                          v-b-tooltip.hover.right.top.v-info
                          title="Toggle Notes"
                          icon="InfoIcon"
                          class="mr-25 text-info cursor-pointer"
                        /> -->
                        {{ gap.title }}
                      </p>
                      <template v-if="gap.notes != 'null'">
                        <small
                          v-if="!gapHeaderDetailsExpanded"
                          class="text-primary cursor-pointer align-middle"
                          :class="gapHeaderDetailsExpanded ? null : 'collapsed'"
                          :aria-expanded="
                            gapHeaderDetailsExpanded ? 'true' : 'false'
                          "
                          aria-controls="collapse_notes"
                          @click="
                            gapHeaderDetailsExpanded = !gapHeaderDetailsExpanded
                          "
                          ><feather-icon
                            icon="ChevronDownIcon"
                            class="mr-25"
                          />See More
                        </small>

                        <b-collapse
                          id="collapse_notes"
                          v-model="gapHeaderDetailsExpanded"
                          class="mt-25"
                          v-if="gap"
                        >
                          <p
                            class="card-text mb-0"
                            v-if="gap.notes && gap.notes != null"
                          >
                            {{ gap.notes }}
                          </p>
                          <small
                            v-if="gapHeaderDetailsExpanded"
                            class="text-primary cursor-pointer align-middle"
                            :class="
                              gapHeaderDetailsExpanded ? null : 'collapsed'
                            "
                            :aria-expanded="
                              gapHeaderDetailsExpanded ? 'true' : 'false'
                            "
                            aria-controls="collapse_notes"
                            @click="
                              gapHeaderDetailsExpanded =
                                !gapHeaderDetailsExpanded
                            "
                            ><feather-icon
                              icon="ChevronUpIcon"
                              class="mr-25"
                            />See Less</small
                          >
                        </b-collapse>
                      </template>
                      <div
                        class="d-flex flex-row align-items-center justify-content-between mt-75"
                      >
                        <progress-badge
                          v-for="chart in chartList"
                          :key="chart.title"
                          :value="chart.value"
                          :min="1"
                          :max="100"
                          :title="chart.title"
                          :colorsReversed="chart.colorsReversed"
                        />
                      </div>
                    </div>

                    <div style="height: 100%; overflow-y: auto">
                      <b-list-group
                        v-if="standard !== null"
                        class="list-group-filters pb-1 pt-0"
                      >
                        <template v-for="family in standard.control_families">
                          <b-list-group-item
                            style="padding: 0"
                            :key="family._id"
                            :class="{ active: family._id === activeFamilyId }"
                            class="w-100"
                          >
                            <div
                              style="min-height: 50px; width: 100%"
                              class="d-flex flex-column justify-content-center align-items-start border-bottom border-left-0 border-right-0 px-75 py-50"
                              :class="{
                                'border-primary': family._id === activeFamilyId,
                              }"
                            >
                              <div
                                class="d-flex flex-row justify-content-start align-items-center"
                              >
                                <!-- <div
                                  class="h-100 d-flex align-items-center justify-content-center"
                                >
                                  <feather-icon
                                    class="mr-1"
                                    :class="
                                      family._id === activeFamilyId
                                        ? 'text-primary'
                                        : 'text-secondary'
                                    "
                                    :icon="
                                      family._id === activeFamilyId
                                        ? 'TargetIcon'
                                        : 'CircleIcon'
                                    "
                                  />
                                </div> -->
                                <small
                                  ><b-badge
                                    variant="warning"
                                    pill
                                    class="mr-50"
                                    >{{ family.control_no }}</b-badge
                                  ></small
                                >
                                <small
                                  class="font-weight-bold cursor-pointer"
                                  @click="handleFamilyClick(family._id)"
                                >
                                  {{ family.name }}
                                </small>
                              </div>

                              <template
                                v-if="
                                  family.control_groups &&
                                  family.control_groups.length > 0
                                "
                              >
                                <div
                                  :key="group._id"
                                  v-for="group in family.control_groups"
                                >
                                  <p
                                    class="text-turncate font-weight-bold mb-0 pl-3 py-1 cursor-pointer"
                                    :class="{
                                      'text-primary font-weight-bold':
                                        group._id === activeGroupId,
                                    }"
                                    @click="
                                      handleGroupClick(family._id, group._id)
                                    "
                                  >
                                    {{ group.name }}
                                  </p>
                                </div>
                              </template>
                            </div>
                          </b-list-group-item>
                        </template>
                      </b-list-group>
                    </div>
                  </div>

                  <div
                    class="d-flex justify-content-center align-items-center w-100 px-2 mb-0 border rounded-sm"
                    style="height: 70px"
                  >
                    <b-button
                      variant="info"
                      size="sm"
                      class="m-0"
                      @click="handleGapAssessmentBulkUploadClick(gap._id)"
                      ><feather-icon icon="UploadIcon" class="mr-75" />
                      <span class="text-center">Evidence Bulk Upload</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="content-right"
            style="max-width: 75%; position: relative; height: 100%"
          >
            <bulk-evidence-upload-section
              v-if="bulkUploadGapId !== null"
              :gapAssessmentEvidenceBulkUpload="gapAssessmentEvidenceBulkUpload"
              :uploadedEvidences="bulkUploadedEvidences"
              :reloadParent="load"
              :gapAssessmentId="bulkUploadGapId"
              :refetchEvidences="getGapAssessmentEvidenceFilesAndSetData"
              :controls="standard"
            />
            <div
              class="content-body"
              style="height: 100%; position: relative"
              v-if="activeFamilyId && bulkUploadGapId === null && showOverlay==false"
            >
              <div
                class="todo-app-list"
                style="position: relative; height: 100%"
              >
                <div
                  style="max-height: 20%; min-height: 15%"
                  class="w-100 app-fixed-search d-flex flex-column align-items-start justify-content-around"
                >
                  <h4 class="font-weight-bold ml-1 pt-25 pb-25">
                    {{ getFamilyName(activeFamilyId) }}
                  </h4>
                  <div
                    class="d-flex align-content-center justify-content-between w-100 p-25"
                  >
                    <b-input-group style="width: 60%">
                      <b-input-group-prepend is-text>
                        <feather-icon
                          icon="XIcon"
                          v-if="
                            (filters.searchPhrase !== null &&
                              filters.searchPhrase !== '') ||
                            controlGapFilter !== null
                          "
                          @click="handleFiltersReset"
                          size="18"
                          class="cursor-pointer text-danger"
                        />
                        <feather-icon v-else icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        class="h-100"
                        :class="{
                          'border border-top-0 border-right-0 border-left-0':
                            filters.searchPhrase !== null &&
                            filters.searchPhrase !== '',
                        }"
                        v-model="filters.searchPhrase"
                        placeholder="Search names, control no, sub-control No..."
                      />
                      <b-input-group-append is-text> </b-input-group-append>
                    </b-input-group>

                    <div
                      class="d-flex justify-content-around align-items-center rounded p-0 mr-50"
                    >
                      <select
                        name=""
                        id=""
                        class="custom-select custom-select-sm"
                        v-model="controlGapTagFilter"
                      >
                        <option :value="null">--Filter By Tags--</option>
                        <option
                          v-for="(opt, i) in tag_options"
                          :key="i"
                          :value="opt.value"
                        >
                          {{ opt.text }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="d-flex justify-content-around align-items-center rounded p-0"
                    >
                      <b-form-select
                        size="sm"
                        v-model="controlGapResponseFilter"
                        :options="controlGapResponseFilterOptions"
                        class="border-primary"
                      />
                    </div>

                    <div
                      class="d-flex justify-content-around align-items-center rounded p-0 ml-50"
                    >
                      <b-form-select
                        size="sm"
                        v-model="controlGapEvidenceStatusFilter"
                        :options="controlGapEvidenceStatusFilterOptions"
                        class="border-primary"
                      />
                    </div>
                  </div>
                </div>

                <vue-perfect-scrollbar
                  :settings="{ maxScrollbarLength: 120 }"
                  class="todo-task-list-wrapper w-100"
                  style="min-height: 80%; max-height: 85%"
                >
                  <template v-if="showOverlay">
                    <div style="display: flex; justify-content: center">
                      <span class="text-primary">
                        <b-spinner small class="mr-1" variant="primary" />Please
                        wait ..</span
                      >
                    </div>
                  </template>
                  <template v-else>
                    <template v-for="response in responses">
                      <transition name="fade" :key="response._id">
                        <!--Type is Group -->
                        <div
                          :key="response._id"
                          v-if="
                            response.type == 'Group' &&
                            !checkIfAllResponsesAreEmpty(response)
                          "
                          class="list-item border border-light"
                          @mouseenter="handleResponseHover(response._id)"
                          @mouseleave="handleResponseHover(null)"
                        >
                          <div
                            class="d-flex flex-row justify-content-between align-items-start w-100 rounded bg-light-primary border-light"
                            :class="{
                              'border-primary':
                                activeResponseId !== null &&
                                activeResponseId === response._id,
                            }"
                            style="transition: all 0.3s ease-in-out"
                          >
                            <div
                              style="width: 55%"
                              class="d-flex h-100 align-items-center justify-content-start"
                            >
                              <div
                                class="d-flex flex-column align-items-start justify-content-start p-1 h-100"
                              >
                                <h4 class="mb-50 font-weight-bold">
                                  Control No:
                                  <b-badge variant="primary" class="mx-75">
                                    <text-highlight
                                      :queries="filters.searchPhrase"
                                      >{{ response.control_no }}</text-highlight
                                    ></b-badge
                                  >
                                </h4>
                                <div class="d-inline-block w-100">
                                  <p
                                    style="
                                      overflow: hidden;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical;
                                    "
                                    class="text-left font-weight-bold mb-0"
                                  >
                                    <text-highlight
                                      :queries="filters.searchPhrase"
                                      >{{ response.name }}</text-highlight
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              style="width: 40%"
                              class="d-flex flex-row align-items-start justify-content-around"
                            >
                              <div
                                class="d-flex flex-row justify-content-center align-items-center p-1"
                              >
                                <div
                                  class="d-flex flex-column justify-content-start align-items-start"
                                >
                                  <h4 class="font-weight-bold mb-50">
                                    Progress
                                  </h4>
                                  <progress-badge2
                                    :max="100"
                                    :min="0"
                                    :value="
                                      percentage(
                                        getTotalResponseAssessedQuestions(
                                          response._id
                                        ),
                                        getTotalResponseQuestions(response._id)
                                      )
                                    "
                                    title="Progress"
                                    :colorsReversed="true"
                                    class="mb-50"
                                  />
                                  <p class="font-weight-bold">
                                    <span class="font-weight-bold">{{
                                      getTotalResponseAssessedQuestions(
                                        response._id
                                      )
                                    }}</span
                                    >/{{
                                      getTotalResponseQuestions(response._id)
                                    }}
                                    Questions Assessed
                                  </p>
                                </div>
                              </div>

                              <div
                                class="d-flex flex-row justify-content-center align-items-start p-1"
                              >
                                <div
                                  class="d-flex flex-column justify-content-start align-items-center"
                                >
                                  <h4 class="font-weight-bold">Compliance</h4>
                                  <progress-badge2
                                    :max="100"
                                    :min="0"
                                    :value="getGroupCompliance(response)"
                                    title="Compliance"
                                    :colorsReversed="false"
                                    class="mb-50"
                                  />
                                  <!-- <b>{{ getGroupCompliance(response) }}%</b> -->
                                  <!-- <div
                                  class="d-flex align-items-center justify-content-center p-0"
                                ></div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="header-section pt-1"
                            v-if="checkResponseMatchFilters(response)"
                          >
                            <div
                              style="min-width: 100px; max-width: 180px"
                              class="d-flex align-items-center justify-content-start"
                            >
                              <b-badge variant="primary" class="mx-75">{{
                                response.control_no
                              }}</b-badge>
                            </div>
                            <div
                              style="width: 100%"
                              class="d-flex justify-content-start align-items-center"
                            >
                              <h4 class="mb-0 font-weight-bold">
                                <text-highlight
                                  :queries="filters.searchPhrase"
                                  >{{ response.name }}</text-highlight
                                >
                              </h4>
                            </div>
                            <!--Control compliance-->
                            <div>
                              <!-- hallo -->
                              <span
                                style="
                                  background: rgb(40 199 111);
                                  color: #fff;
                                  padding: 5px;
                                  border-radius: 5px;
                                "
                                class="mr-1"
                              >
                                {{ getGroupCompliance(response) }}%
                              </span>
                            </div>
                          </div>

                          <div
                            class="content-section d-flex flex-column justify-content-center align-items-center w-100"
                          >
                            <template v-for="item in response.responses">
                              <div
                                :key="item._id"
                                v-if="
                                  checkResponseTypeFilter(item) &&
                                  checkEvidenceStatusFilter(item) &&
                                  checkTagFilter(item)
                                "
                                class="d-flex justify-content-between align-items-center w-100 my-1"
                              >
                                <div
                                  style="width: 120px"
                                  class="d-flex flex-row align-items-center justify-content-start"
                                >
                                  <b-badge
                                    variant="light-warning"
                                    class="mx-50"
                                    pill
                                    v-b-tooltip.hover.top.v-secondary
                                    :title="
                                      'Updated At ' +
                                      formatTimestamp(standard.updated_at)
                                    "
                                    v-if="
                                      showUpdateBadge(
                                        standard.updated_at,
                                        item.updated_at
                                      )
                                    "
                                    >Updated<feather-icon
                                      class="text-danger cursor-pointer ml-25"
                                      icon="XIcon"
                                      @click="handleUpdatedBadgeClick(item)"
                                  /></b-badge>
                                </div>
                                <div
                                  style="width: 65%"
                                  class="d-flex flex-row justify-content-start align-items-center mr-2"
                                >
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                    style="height: 100%"
                                  >
                                    <feather-icon
                                      icon="HelpCircleIcon"
                                      class="mr-75 text-primary"
                                      size="18"
                                    />
                                  </div>

                                  <h6
                                    class="mb-0 text-primary font-weight-bold cursor-pointer"
                                    style="font-size: 16px"
                                    @click="
                                      handleQuestionClick({
                                        response: item,
                                      })
                                    "
                                  >
                                    <text-highlight
                                      :queries="filters.searchPhrase"
                                      >{{
                                        item.question.question
                                      }}</text-highlight
                                    >
                                  </h6>
                                  <b-badge
                                    variant="warning"
                                    class="mr-50 ml-50"
                                    v-for="tag in item.tags"
                                    :key="tag"
                                    >{{ tag }}</b-badge
                                  >
                                </div>

                                <div
                                  class="d-flex justify-content-end align-items-center mr-1"
                                  style="width: 30%"
                                >
                                  <template v-if="item.response !== null">
                                    <b-badge
                                      variant="primary"
                                      v-if="item.status == 3"
                                      >Not Applicable</b-badge
                                    >
                                    <b-badge variant="primary" v-else>{{
                                      getResponseValue(item.response_value)
                                    }}</b-badge>
                                  </template>

                                  <template
                                    v-if="item.evidence_status !== null"
                                  >
                                    <b-badge
                                      variant="light-primary"
                                      class="ml-1"
                                      >{{
                                        getStatusValue(item.evidence_status)
                                      }}</b-badge
                                    >
                                  </template>
                                </div>

                                <!-- {{ item.tags }} -->
                              </div>
                            </template>

                            <!-- Group Controls Section -->
                            <template v-if="response.controls.length !== 0">
                              <template v-for="control in response.controls">
                                <div
                                  :key="control._id"
                                  class="d-flex flex-column justify-content-start align-items-start w-100"
                                >
                                  <div
                                    class="d-flex flex-row justify-content-start align-items-center w-100 mt-1"
                                    v-if="checkResponseMatchFilters(control)"
                                  >
                                    <div
                                      style="min-width: 100px"
                                      class="d-flex align-items-center justify-content-start"
                                    >
                                      <b-badge variant="primary" class="mx-75">
                                        <text-highlight
                                          :queries="filters.searchPhrase"
                                          >{{
                                            control.control_no
                                          }}</text-highlight
                                        ></b-badge
                                      >
                                    </div>
                                    <div
                                      style="width: 100%"
                                      class="d-flex justify-content-between align-items-center"
                                    >
                                      <h4 class="mb-0 font-weight-bold">
                                        <text-highlight
                                          :queries="filters.searchPhrase"
                                          >{{ control.name }}</text-highlight
                                        >
                                      </h4>
                                      <div>
                                        <span
                                          style="
                                            background: rgb(40 199 111);
                                            color: #fff;
                                            padding: 5px;
                                            border-radius: 5px;
                                          "
                                          class="mr-1"
                                        >
                                          {{ getControlCompliance(control) }}%
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex flex-column justify-content-center align-items-center w-100"
                                  >
                                    <template v-for="item in control.responses">
                                      <div
                                        :key="item._id"
                                        v-if="
                                          checkResponseTypeFilter(item) &&
                                          checkEvidenceStatusFilter(item) &&
                                          checkTagFilter(item)
                                        "
                                        class="d-flex justify-content-between align-items-center w-100 my-1"
                                      >
                                        <div
                                          style="width: 120px"
                                          class="d-flex flex-row align-items-center justify-content-start"
                                        >
                                          <b-badge
                                            variant="light-warning"
                                            class="mx-50"
                                            pill
                                            v-b-tooltip.hover.top.v-secondary
                                            :title="
                                              'Updated At ' +
                                              formatTimestamp(
                                                standard.updated_at
                                              )
                                            "
                                            v-if="
                                              showUpdateBadge(
                                                standard.updated_at,
                                                item.updated_at
                                              )
                                            "
                                            >Updated<feather-icon
                                              class="text-danger cursor-pointer ml-25"
                                              icon="XIcon"
                                              @click="
                                                handleUpdatedBadgeClick(item)
                                              "
                                          /></b-badge>
                                        </div>
                                        <div
                                          style="width: 65%"
                                          class="d-flex flex-row justify-content-start align-items-center mr-2"
                                        >
                                          <div
                                            class="d-flex justify-content-center align-items-center"
                                            style="height: 100%"
                                          >
                                            <feather-icon
                                              icon="HelpCircleIcon"
                                              class="mr-75 text-primary"
                                              size="18"
                                            />
                                          </div>

                                          <h6
                                            class="mb-0 text-primary font-weight-bold cursor-pointer"
                                            style="font-size: 16px"
                                            @click="
                                              handleQuestionClick({
                                                response: item,
                                              })
                                            "
                                          >
                                            <text-highlight
                                              :queries="filters.searchPhrase"
                                              >{{
                                                item.question.question
                                              }}</text-highlight
                                            >
                                          </h6>
                                          <b-badge
                                            variant="warning"
                                            class="mr-50 ml-50"
                                            v-for="tag in item.tags"
                                            :key="tag"
                                            >{{ tag }}</b-badge
                                          >
                                        </div>

                                        <div
                                          class="d-flex justify-content-end align-items-center mr-1"
                                          style="width: 30%"
                                        >
                                          <template
                                            v-if="item.response !== null"
                                          >
                                            <b-badge
                                              variant="primary"
                                              v-if="item.status == 3"
                                              >Not Applicable</b-badge
                                            >
                                            <b-badge variant="primary" v-else>{{
                                              getResponseValue(
                                                item.response_value
                                              )
                                            }}</b-badge>
                                          </template>

                                          <template
                                            v-if="item.evidence_status !== null"
                                          >
                                            <b-badge
                                              variant="light-primary"
                                              class="ml-1"
                                              >{{
                                                getStatusValue(
                                                  item.evidence_status
                                                )
                                              }}</b-badge
                                            >
                                          </template>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                                <template
                                  v-if="control.subcontrols.length !== 0"
                                >
                                  <div
                                    v-for="subcontrol in control.subcontrols"
                                    :key="subcontrol._id"
                                    class="d-flex flex-column justify-content-start align-items-start w-100"
                                  >
                                    <div
                                      class="d-flex flex-row justify-content-start align-items-center w-100 mt-1"
                                      v-if="
                                        checkResponseMatchFilters(subcontrol)
                                      "
                                    >
                                      <div
                                        style="min-width: 100px"
                                        class="d-flex align-items-center justify-content-start"
                                      >
                                        <b-badge
                                          variant="primary"
                                          class="mx-75"
                                        >
                                          <text-highlight
                                            :queries="filters.searchPhrase"
                                            >{{
                                              subcontrol.control_no
                                            }}</text-highlight
                                          ></b-badge
                                        >
                                      </div>
                                      <div
                                        style="width: 100%"
                                        class="d-flex justify-content-between align-items-center"
                                      >
                                        <h4 class="mb-0 font-weight-bold">
                                          <text-highlight
                                            :queries="filters.searchPhrase"
                                            >{{
                                              subcontrol.name
                                            }}</text-highlight
                                          >
                                        </h4>
                                        <div>
                                          <span
                                            style="
                                              background: rgb(40 199 111);
                                              color: #fff;
                                              padding: 5px;
                                              border-radius: 5px;
                                            "
                                            class="mr-1"
                                          >
                                            {{
                                              getGroupCompliance(subcontrol)
                                            }}%
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex flex-column justify-content-center align-items-center w-100"
                                    >
                                      <template
                                        v-for="response in subcontrol.responses"
                                      >
                                        <div
                                          :key="response._id"
                                          v-if="
                                            checkResponseTypeFilter(response) &&
                                            checkEvidenceStatusFilter(
                                              response
                                            ) &&
                                            checkTagFilter(response)
                                          "
                                          class="d-flex justify-content-between align-items-center w-100 my-1"
                                        >
                                          <div
                                            style="width: 120px"
                                            class="d-flex flex-row align-items-center justify-content-start"
                                          >
                                            <b-badge
                                              variant="light-warning"
                                              class="mx-50"
                                              pill
                                              v-b-tooltip.hover.top.v-secondary
                                              :title="
                                                'Updated At ' +
                                                formatTimestamp(
                                                  standard.updated_at
                                                )
                                              "
                                              v-if="
                                                showUpdateBadge(
                                                  standard.updated_at,
                                                  response.updated_at
                                                )
                                              "
                                              >Updated<feather-icon
                                                class="text-danger cursor-pointer ml-25"
                                                icon="XIcon"
                                                @click="
                                                  handleUpdatedBadgeClick(
                                                    response
                                                  )
                                                "
                                            /></b-badge>
                                          </div>
                                          <div
                                            style="width: 65%"
                                            class="d-flex flex-row justify-content-start align-items-center mr-2"
                                          >
                                            <div
                                              class="d-flex justify-content-center align-items-center"
                                              style="height: 100%"
                                            >
                                              <feather-icon
                                                icon="HelpCircleIcon"
                                                class="mr-75 text-primary"
                                                size="18"
                                              />
                                            </div>

                                            <h6
                                              class="mb-0 text-primary font-weight-bold cursor-pointer"
                                              style="font-size: 16px"
                                              @click="
                                                handleQuestionClick({
                                                  response: response,
                                                })
                                              "
                                            >
                                              <text-highlight
                                                :queries="filters.searchPhrase"
                                                >{{
                                                  response.question.question
                                                }}</text-highlight
                                              >
                                            </h6>
                                            <b-badge
                                              variant="warning"
                                              class="ml-50 mr-50"
                                              v-for="tag in response.tags"
                                              :key="tag"
                                              >{{ tag }}</b-badge
                                            >
                                          </div>

                                          <div
                                            class="d-flex justify-content-end align-items-center mr-1"
                                            style="width: 30%"
                                          >
                                            <template
                                              v-if="response.response !== null"
                                            >
                                              <b-badge
                                                variant="primary"
                                                v-if="response.status == 3"
                                                >Not Applicable</b-badge
                                              >
                                              <b-badge
                                                variant="primary"
                                                v-else
                                                >{{
                                                  getResponseValue(
                                                    response.response_value
                                                  )
                                                }}</b-badge
                                              >
                                            </template>

                                            <template
                                              v-if="
                                                response.evidence_status !==
                                                null
                                              "
                                            >
                                              <b-badge
                                                variant="light-primary"
                                                class="ml-1"
                                                >{{
                                                  getStatusValue(
                                                    response.evidence_status
                                                  )
                                                }}</b-badge
                                              >
                                            </template>
                                          </div>
                                        </div>
                                      </template>
                                    </div>
                                  </div>
                                </template>
                              </template>
                            </template>
                          </div>
                        </div>

                        <!--Type is Control -->
                        <div
                          :key="response._id"
                          v-if="
                            response.type == 'Control' &&
                            !checkIfAllResponsesAreEmpty(response)
                          "
                          class="list-item border border-light"
                          @mouseenter="handleControlHover(response._id)"
                          @mouseleave="handleControlHover(null)"
                        >
                          <div
                            class="d-flex flex-row justify-content-between align-items-start w-100 rounded bg-light-primary border-light"
                            :class="{
                              'border-primary':
                                activeControlId !== null &&
                                activeControlId === response._id,
                            }"
                            style="transition: all 0.3s ease-in-out"
                          >
                            <div
                              style="width: 55%"
                              class="d-flex h-100 align-items-center justify-content-start"
                            >
                              <div
                                class="d-flex flex-column align-items-start justify-content-start p-1 h-100"
                              >
                                <h4 class="mb-50 font-weight-bold">
                                  Control No:
                                  <b-badge variant="primary" class="mx-75">
                                    <text-highlight
                                      :queries="filters.searchPhrase"
                                      >{{ response.control_no }}</text-highlight
                                    ></b-badge
                                  >
                                </h4>
                                <div class="d-inline-block w-100">
                                  <p
                                    style="
                                      overflow: hidden;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 3;
                                      -webkit-box-orient: vertical;
                                    "
                                    class="text-left font-weight-bold mb-0"
                                  >
                                    <text-highlight
                                      :queries="filters.searchPhrase"
                                      >{{ response.name }}</text-highlight
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div
                              style="width: 40%"
                              class="d-flex flex-row align-items-start justify-content-around"
                            >
                              <div
                                class="d-flex flex-row justify-content-center align-items-center p-1"
                              >
                                <div
                                  class="d-flex flex-column justify-content-start align-items-start"
                                >
                                  <h4 class="font-weight-bold mb-50">
                                    Progress
                                  </h4>
                                  <progress-badge2
                                    :max="100"
                                    :min="0"
                                    :value="
                                      percentage(
                                        getTotalControlAssessedQuestions(
                                          response._id
                                        ),
                                        getTotalControlQuestions(response._id)
                                      )
                                    "
                                    title="Progress"
                                    :colorsReversed="true"
                                    class="mb-50"
                                  />
                                  <p class="font-weight-bold">
                                    <span class="font-weight-bold">{{
                                      getTotalControlAssessedQuestions(
                                        response._id
                                      )
                                    }}</span
                                    >/{{
                                      getTotalControlQuestions(response._id)
                                    }}
                                    Questions Assessed
                                  </p>
                                </div>
                              </div>

                              <div
                                class="d-flex flex-row justify-content-center align-items-start p-1"
                              >
                                <div
                                  class="d-flex flex-column justify-content-start align-items-center"
                                >
                                  <h4 class="font-weight-bold">Compliance</h4>

                                  <div
                                    class="d-flex align-items-center justify-content-center p-0"
                                  >
                                    <b>
                                      {{ getControlCompliance(response) }}
                                      %</b
                                    >
                                    <!-- <b>
                                    {{
                                      getControlCompliance(response).toFixed(
                                        2
                                      ) + "%"
                                    }}</b
                                  > -->
                                    <!-- <b-avatar
                                    size="35px"
                                    :variant="
                                      getControlGapStatus(response._id) == 1 &&
                                      getTotalControlAssessedQuestions(
                                        response._id
                                      ) ==
                                        getTotalControlQuestions(response._id)
                                        ? 'light-success'
                                        : 'light-warning'
                                    "
                                  >
                                    <feather-icon
                                      size="20"
                                      :icon="
                                        getControlGapStatus(response._id) ==
                                          1 &&
                                        getTotalControlAssessedQuestions(
                                          response._id
                                        ) ==
                                          getTotalControlQuestions(response._id)
                                          ? 'CheckCircleIcon'
                                          : 'AlertCircleIcon'
                                      "
                                    />
                                  </b-avatar>
                                </div>
                                <p
                                  class="font-weight-bold"
                                  :class="[
                                    getControlGapStatus(response._id) == 1 &&
                                    getTotalControlAssessedQuestions(
                                      response._id
                                    ) == getTotalControlQuestions(response._id)
                                      ? 'text-success'
                                      : 'text-warning',
                                  ]"
                                >
                                  {{
                                    getControlGapStatus(response._id) == 1 &&
                                    getTotalControlAssessedQuestions(
                                      response._id
                                    ) == getTotalControlQuestions(response._id)
                                      ? "Completed"
                                      : "Partial"
                                  }}
                                </p> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="header-section pt-1"
                            v-if="checkResponseMatchFilters(response)"
                          >
                            <div
                              style="min-width: 100px; max-width: 180px"
                              class="d-flex align-items-center justify-content-start"
                            >
                              <b-badge variant="primary" class="mx-75">{{
                                response.control_no
                              }}</b-badge>
                            </div>
                            <div
                              style="width: 100%"
                              class="d-flex justify-content-between align-items-center"
                            >
                              <h4 class="mb-0 font-weight-bold">
                                <text-highlight
                                  :queries="filters.searchPhrase"
                                  >{{ response.name }}</text-highlight
                                >
                              </h4>
                            </div>
                            <div>
                              <span
                                style="
                                  background: rgb(40 199 111);
                                  color: #fff;
                                  padding: 5px;
                                  border-radius: 5px;
                                "
                                class="mr-1"
                              >
                                {{ getControlCompliance(response) }}%
                              </span>
                            </div>
                          </div>

                          <div
                            class="content-section d-flex flex-column justify-content-center align-items-center w-100"
                          >
                            <template v-for="item in response.responses">
                              <div
                                :key="item._id"
                                v-if="
                                  checkResponseTypeFilter(item) &&
                                  checkEvidenceStatusFilter(item) &&
                                  checkTagFilter(item)
                                "
                                class="d-flex justify-content-between align-items-center w-100 my-1"
                              >
                                <div
                                  style="width: 120px"
                                  class="d-flex flex-row align-items-center justify-content-start"
                                >
                                  <b-badge
                                    variant="light-warning"
                                    class="mx-50"
                                    pill
                                    v-b-tooltip.hover.top.v-secondary
                                    :title="
                                      'Updated At ' +
                                      formatTimestamp(standard.updated_at)
                                    "
                                    v-if="
                                      showUpdateBadge(
                                        standard.updated_at,
                                        item.updated_at
                                      )
                                    "
                                    >Updated<feather-icon
                                      class="text-danger cursor-pointer ml-25"
                                      icon="XIcon"
                                      @click="handleUpdatedBadgeClick(item)"
                                  /></b-badge>
                                </div>
                                <div
                                  style="width: 65%"
                                  class="d-flex flex-row justify-content-start align-items-center mr-2"
                                >
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                    style="height: 100%"
                                  >
                                    <feather-icon
                                      icon="HelpCircleIcon"
                                      class="mr-75 text-primary"
                                      size="18"
                                    />
                                  </div>

                                  <h6
                                    class="mb-0 text-primary font-weight-bold cursor-pointer"
                                    style="font-size: 16px"
                                    @click="
                                      handleQuestionClick({
                                        response: item,
                                      })
                                    "
                                  >
                                    <text-highlight
                                      :queries="filters.searchPhrase"
                                      >{{
                                        item.question.question
                                      }}</text-highlight
                                    >
                                  </h6>
                                  <b-badge
                                    variant="warning"
                                    class="ml-50 mr-50"
                                    v-for="tag in item.tags"
                                    :key="tag"
                                    >{{ tag }}</b-badge
                                  >
                                  <!-- <b-badge
                                    variant="light-primary"
                                    class="ml-1"
                                    v-for="tag in item.question.tags"
                                    :key="tag.value"
                                    >
                                    {{ tag.title }}
                                    </b-badge
                                  > -->
                                </div>

                                <div
                                  class="d-flex justify-content-end align-items-center mr-1"
                                  style="width: 30%"
                                >
                                  <template v-if="item.response !== null">
                                    <b-badge
                                      variant="primary"
                                      v-if="item.status == 3"
                                      >Not Applicable</b-badge
                                    >
                                    <b-badge variant="primary" v-else>{{
                                      getResponseValue(item.response_value)
                                    }}</b-badge>
                                  </template>

                                  <template
                                    v-if="item.evidence_status !== null"
                                  >
                                    <b-badge
                                      variant="light-primary"
                                      class="ml-1"
                                      >{{
                                        getStatusValue(item.evidence_status)
                                      }}</b-badge
                                    >
                                  </template>
                                </div>
                              </div>
                            </template>

                            <template v-if="response.subcontrols.length !== 0">
                              <div
                                v-for="subcontrol in response.subcontrols"
                                :key="subcontrol._id"
                                class="d-flex flex-column justify-content-start align-items-start w-100"
                              >
                                <div
                                  class="d-flex flex-row justify-content-start align-items-center w-100 mt-1"
                                  v-if="checkResponseMatchFilters(subcontrol)"
                                >
                                  <div
                                    style="min-width: 100px"
                                    class="d-flex align-items-between justify-content-start"
                                  >
                                    <b-badge variant="primary" class="mx-75">
                                      <text-highlight
                                        :queries="filters.searchPhrase"
                                        >{{
                                          subcontrol.control_no
                                        }}</text-highlight
                                      ></b-badge
                                    >
                                  </div>
                                  <div
                                    style="width: 100%"
                                    class="d-flex justify-content-between align-items-center"
                                  >
                                    <h4 class="mb-0 font-weight-bold">
                                      <text-highlight
                                        :queries="filters.searchPhrase"
                                        >{{ subcontrol.name }}</text-highlight
                                      >
                                    </h4>
                                  </div>
                                  <div>
                                    <span
                                      style="
                                        background: rgb(40 199 111);
                                        color: #fff;
                                        padding: 5px;
                                        border-radius: 5px;
                                      "
                                      class="mr-1"
                                    >
                                      {{ getControlCompliance(subcontrol) }}%
                                    </span>
                                  </div>
                                  <div>
                                    <!-- {{ getResponseComplianceStatus(
                                          subcontrol._id
                                        ).toFixed(2) }} -->
                                  </div>
                                </div>
                                <div
                                  class="d-flex flex-column justify-content-center align-items-center w-100"
                                >
                                  <template
                                    v-for="response in subcontrol.responses"
                                  >
                                    <div
                                      :key="response._id"
                                      v-if="
                                        checkResponseTypeFilter(response) &&
                                        checkEvidenceStatusFilter(response) &&
                                        checkTagFilter(response)
                                      "
                                      class="d-flex justify-content-between align-items-center w-100 my-1"
                                    >
                                      <div
                                        style="width: 120px"
                                        class="d-flex flex-row align-items-center justify-content-start"
                                      >
                                        <b-badge
                                          variant="light-warning"
                                          class="mx-50"
                                          pill
                                          v-b-tooltip.hover.top.v-secondary
                                          :title="
                                            'Updated At ' +
                                            formatTimestamp(standard.updated_at)
                                          "
                                          v-if="
                                            showUpdateBadge(
                                              standard.updated_at,
                                              response.updated_at
                                            )
                                          "
                                          >Updated<feather-icon
                                            class="text-danger cursor-pointer ml-25"
                                            icon="XIcon"
                                            @click="
                                              handleUpdatedBadgeClick(response)
                                            "
                                        /></b-badge>
                                      </div>
                                      <div
                                        style="width: 65%"
                                        class="d-flex flex-row justify-content-start align-items-center mr-2"
                                      >
                                        <div
                                          class="d-flex justify-content-center align-items-center"
                                          style="height: 100%"
                                        >
                                          <feather-icon
                                            icon="HelpCircleIcon"
                                            class="mr-75 text-primary"
                                            size="18"
                                          />
                                        </div>

                                        <h6
                                          class="mb-0 text-primary font-weight-bold cursor-pointer"
                                          style="font-size: 16px"
                                          @click="
                                            handleQuestionClick({
                                              response: response,
                                            })
                                          "
                                        >
                                          <text-highlight
                                            :queries="filters.searchPhrase"
                                            >{{
                                              response.question.question
                                            }}</text-highlight
                                          >
                                        </h6>
                                        <b-badge
                                          variant="warning"
                                          class="ml-50 mr-50"
                                          v-for="tag in response.tags"
                                          :key="tag"
                                          >{{ tag }}</b-badge
                                        >
                                      </div>

                                      <div
                                        class="d-flex justify-content-end align-items-center mr-1"
                                        style="width: 30%"
                                      >
                                        <template>
                                          <b-badge
                                            variant="primary"
                                            v-if="response.status == 3"
                                            >Not Applicable</b-badge
                                          >
                                          <b-badge variant="primary" v-else>{{
                                            getResponseValue(
                                              response.response_value
                                            )
                                          }}</b-badge>
                                        </template>

                                        <template
                                          v-if="
                                            response.evidence_status !== null
                                          "
                                        >
                                          <b-badge
                                            variant="light-primary"
                                            class="ml-1"
                                            >{{
                                              getStatusValue(
                                                response.evidence_status
                                              )
                                            }}</b-badge
                                          >
                                        </template>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </template>
                          </div>
                        </div>
                      </transition>
                    </template>
                  </template>
                </vue-perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BButton,
  BListGroup,
  BListGroupItem,
  BSidebar,
  BCard,
  BBadge,
  BOverlay,
  BFormSelect,
  VBTooltip,
  BCol,
  BFormTextarea,
  BProgress,
  VBHover,
  BAvatar,
  BFormCheckbox,
  BCollapse,
  BSpinner,
} from "bootstrap-vue";
import StandardMixins from "../../mixins/StandardMixins";
import GapAssessmentMixins from "../../mixins/GapAssessmentMixins";
import ResponseMixins from "../../mixins/ResponseMixins";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import AddStandardsToGapForm from "./components/AddStandardsToGapForm.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import TextHighlight from "vue-text-highlight";
import draggable from "vuedraggable";
import { VBToggle } from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import ProgressBar from "../../components/ProgressBar.vue";
import ProgressBadge from "../../components/ProgressBar2.vue";
import ProgressBadge2 from "../../components/ProgressBadge.vue";

import DonutBar from "../../components/DonutBar.vue";
import GapQuestionAssessForm from "./components/GapQuestionAssessForm.vue";
import UserMixins from "../../mixins/UserMixins";
import BulkEvidenceUploadSection from "./components/BulkEvidenceUploadSection.vue";
import UtilsMixins from "../../mixins/UtilsMixins";
import BackButton from "@/components/BackButton.vue";
export default {
  name: "ControlGaps",
  components: {
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    draggable,
    BSidebar,
    BCard,
    VBToggle,
    BBadge,
    BOverlay,
    BFormSelect,
    VueAutosuggest,
    VBTooltip,
    ValidationProvider,
    ValidationObserver,
    AddStandardsToGapForm,
    BCol,
    BFormTextarea,
    BProgress,
    ProgressBar,
    DonutBar,
    GapQuestionAssessForm,
    BulkEvidenceUploadSection,
    VBHover,
    BAvatar,
    TextHighlight,
    BFormCheckbox,
    ProgressBadge,
    ProgressBadge2,
    BCollapse,
    BSpinner,
    BackButton,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    "b-hover": VBHover,
  },
  data() {
    return {
      gapHeaderDetailsExpanded: false,
      filters: {
        searchPhrase: "",
      },
      responses: [],
      nonFilteredResponses: [],
      activeResponseId: null,
      onlyCompletedResponses: false,
      onlyCompletedControls: false,
      controlGapFilter: null,
      controlGapFilterOptions: [
        { text: "--Filter By Compliance Status--", value: null },
        { text: "Partial", value: 0 },
        { text: "Completed", value: 1 },
      ],
      controlGapResponseFilter: null,
      controlGapResponseFilterOptions: [
        { text: "--Filter By Response Type--", value: null },
        { text: "Not Applicable", value: -1 },
        { text: "No Response", value: -2 },
      ],
      controlGapEvidenceStatusFilter: null,
      controlGapEvidenceStatusFilterOptions: [
        { text: "--Filter By Evidence Status--", value: null },
        { text: "No Evidence", value: 0 },
        { text: "Evidence Requested", value: 1 },
        { text: "Uploaded", value: 2 },
        { text: "Evidence Approved", value: 3 },
        { text: "Rejected", value: 4 },
      ],
      listItems: null,
      allControls: null,
      showOverlay: false,
      bulkUploadGapId: null,
      bulkUploadedEvidences: [],
      gap: null,
      standard: null,
      activeFamilyId: null,
      activeGroupId: null,
      activeControlId: null,
      controls: [],
      allControls: [],
      selectedResponse: null,
      selectedControlIndex: null,
      selectedSubcontrolIndex: null,
      selectedResponseIndex: null,
      currentUser: null,
      progressCalcControls: [],
      selectedGap: {
        observation: null,
        isCompensatoryControlsUsed: false,
        compensatory_compliance_note: null,
        response_value: null,
        evidenceFiles: null,
        evidenceResponsibleUser: null,
        gapAssessmentId: null,
        gapAssessmentResponseId: null,
        recommendation: null,
        taskDescription: null,
        taskName: null,
        notifyTaskAssignee: false,
        dueDate: null,
      },
      responseValues: null,
      responseValuesControl: null,
      responseValuesSubcontrol: null,
      chartList: [],
      tag_options: [],
      all_tags: [],
      controlGapTagFilter: null,
      responseSets: [],
      show: true,
    };
  },

  watch: {
    filters: {
      handler: function (newFilters) {
        //Search Phrase Filter Section
        if (
          newFilters.searchPhrase !== null &&
          newFilters.searchPhrase !== ""
        ) {
          let newControls = this.allControls.filter((control) => {
            if (
              control.name
                .toLowerCase()
                .includes(newFilters.searchPhrase.toLowerCase()) ||
              control.control_no
                .toLowerCase()
                .includes(newFilters.searchPhrase.toLowerCase()) ||
              this.searchPhraseInControlQuestions(
                newFilters.searchPhrase.toLowerCase(),
                control
              ) ||
              this.searchPhraseInSubControlName(
                newFilters.searchPhrase.toLowerCase(),
                control
              ) ||
              this.searchPhraseInSubControlNumber(
                newFilters.searchPhrase.toLowerCase(),
                control
              ) ||
              this.searchPhraseInSubControlQuestions(
                newFilters.searchPhrase.toLowerCase(),
                control
              )
            ) {
              return true;
            } else return false;
          });
          this.controls = newControls;
        } else {
          this.controls = this.allControls;
        }
      },
      deep: true,
    },

    activeFamilyId(newVal) {
      if (newVal !== null) {
        // this.getGapControlsAndSetData(this.$route.params.id, {
        //   control_family_id: newVal,
        // });
        this.getGapAssessmentResponsesAndSetData(this.$route.params.id, {
          control_family_id: newVal,
        });
      }
    },

    activeGroupId(newVal) {
      if (newVal !== null && this.activeFamilyId !== null) {
        // this.getGapControlsAndSetData(this.$route.params.id, {
        //   control_group_id: newVal,
        // });
        this.getGapAssessmentResponsesAndSetData(this.$route.params.id, {
          control_group_id: newVal,
        });
      }
    },
  },
  mounted() {
    this.load();
  },
  mixins: [
    GapAssessmentMixins,
    StandardMixins,
    ResponseMixins,
    UserMixins,
    UtilsMixins,
  ],
  methods: {
    GenerateReport(gapid) {
      this.postGenerate(gapid)
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    DownloadReport(gapid) {
      this.getGenerate(gapid)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "tasks.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    load() {
      this.getGapAssessmentDetailsAndSetData(this.$route.params.id);
      this.getCurrentUserDetailsAndSetData();
    },

    showUpdateBadge(standardUpdatedTime, responseUpdatedTime) {
      if (new Date(standardUpdatedTime) > new Date(responseUpdatedTime)) {
        return true;
      } else {
        return false;
      }
    },

    handleUpdatedBadgeClick(response) {
      let obj = {
        response_value: response.response_value,
        dismiss_is_updated_flag: 1,
        gapAssessmentId: response.gap_assessment_id,
        gapAssessmentResponseId: response._id,
      };

      this.showOverlay = true;
      this.updateGapAssessmentResponses(obj)
        .then((res) => {
          this.updateGapAssessmentResponsesFromResponse(res);
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getComplianceBadgeColor(value) {
      if (value == 1) {
        return "light-success";
      } else {
        return "warning-success";
      }
    },

    handleResponseHover(responseId) {
      this.activeResponseId = responseId;
    },

    getTotalResponseAssessedQuestions(responseId) {
      const responseIndex = this.responses.findIndex(
        (response) => response._id === responseId
      );
      let response = this.responses[responseIndex];
      let responseItems = [];
      if (
        (response.responses && response.responses.length !== 0) ||
        response.responses !== null
      ) {
        for (let i = 0; i < response.responses.length; i++) {
          if (
            response.responses[i].response &&
            response.responses[i].response !== null
          ) {
            responseItems.push({
              id: response.responses[i]._id,
              response: response.responses[i].response,
            });
          }
        }
      }
      if (
        (response.controls && response.controls.length !== 0) ||
        response.controls !== null
      ) {
        let newControls = response.controls;
        newControls.map((control) => {
          if (control.responses.length !== 0 || control.responses !== null) {
            for (let i = 0; i < control.responses.length; i++) {
              if (
                control.responses[i].response &&
                control.responses[i].response !== null
              ) {
                responseItems.push({
                  id: control.responses[i]._id,
                  response: control.responses[i].response,
                });
              }
            }
          }
          if (
            (control.subcontrols && control.subcontrols.length !== 0) ||
            control.subcontrols !== null
          ) {
            let newSubcontrols = control.subcontrols;
            newSubcontrols.map((subControl) => {
              if (
                subControl.responses.length !== 0 ||
                subControl.responses !== null
              ) {
                for (let i = 0; i < subControl.responses.length; i++) {
                  if (
                    subControl.responses[i].response &&
                    subControl.responses[i].response !== null
                  ) {
                    responseItems.push({
                      id: subControl.responses[i]._id,
                      response: subControl.responses[i].response,
                    });
                  }
                }
              }
            });
          }
        });
      }
      return responseItems.length;
    },

    getTotalResponseQuestions(responseId) {
      const responseIndex = this.responses.findIndex(
        (response) => response._id === responseId
      );
      let response = this.responses[responseIndex];
      let responseItems = [];
      if (
        (response.responses && response.responses.length !== 0) ||
        response.responses !== null
      ) {
        for (let i = 0; i < response.responses.length; i++) {
          responseItems.push({
            id: response.responses[i]._id,
            response: response.responses[i].response,
          });
        }
      }
      if (
        (response.controls && response.controls.length !== 0) ||
        response.controls !== null
      ) {
        let newControls = response.controls;
        newControls.map((control) => {
          if (control.responses.length !== 0 || control.responses !== null) {
            for (let i = 0; i < control.responses.length; i++) {
              responseItems.push({
                id: control.responses[i]._id,
                response: control.responses[i].response,
              });
            }
          }
          if (
            (control.subcontrols && control.subcontrols.length !== 0) ||
            control.subcontrols !== null
          ) {
            let newSubcontrols = control.subcontrols;
            newSubcontrols.map((subControl) => {
              if (
                subControl.responses.length !== 0 ||
                subControl.responses !== null
              ) {
                for (let i = 0; i < subControl.responses.length; i++) {
                  responseItems.push({
                    id: subControl.responses[i]._id,
                    response: subControl.responses[i].response,
                  });
                }
              }
            });
          }
        });
      }
      return responseItems.length;
    },

    getResponseComplianceStatus(responseId) {
      const responseIndex = this.responses.findIndex(
        (response) => response._id === responseId
      );

      if (responseIndex !== -1) {
        let response = this.responses[responseIndex];
        let totalValue = 0;
        let totalQs = 0;
        if (
          (response.responses && response.responses.length !== 0) ||
          response.responses !== null
        ) {
          for (let i = 0; i < response.responses.length; i++) {
            if (
              response.responses[i].response &&
              response.responses[i].response !== null &&
              response.responses[i].response !== 3
            ) {
              if (this.controlGapTagFilter !== null) {
                if (
                  response.responses[i].tags.includes(this.controlGapTagFilter)
                ) {
                  totalValue += response.responses[i].response_value;
                }
              } else {
                totalValue += response.responses[i].response_value;
              }
            }
            if (response.responses[i].response !== 3) {
              if (this.controlGapTagFilter !== null) {
                if (
                  response.responses[i].tags.includes(this.controlGapTagFilter)
                ) {
                  totalQs++;
                }
              } else {
                totalQs++;
              }
            }
          }
        }

        if (
          (response.controls && response.controls.length !== 0) ||
          response.controls !== null
        ) {
          let newControls = response.controls ? response.controls : [];

          newControls.map((control) => {
            if (control.responses.length !== 0 || control.responses !== null) {
              for (let i = 0; i < control.responses.length; i++) {
                if (
                  control.responses[i].response &&
                  control.responses[i].response !== null &&
                  control.responses[i].response !== 3
                ) {
                  if (this.controlGapTagFilter !== null) {
                    if (
                      control.responses[i].tags.includes(
                        this.controlGapTagFilter
                      )
                    ) {
                      totalValue += control.responses[i].response_value;
                    }
                  } else {
                    totalValue += control.responses[i].response_value;
                  }
                }
                if (control.responses[i].response !== 3) {
                  if (this.controlGapTagFilter !== null) {
                    if (
                      control.responses[i].tags.includes(
                        this.controlGapTagFilter
                      )
                    ) {
                      totalQs++;
                    }
                  } else {
                    totalQs++;
                  }
                }
              }
            }
            if (
              (control.subcontrols && control.subcontrols.length !== 0) ||
              control.subcontrols !== null
            ) {
              let newSubcontrols = control.subcontrols;
              newSubcontrols.map((subControl) => {
                if (
                  subControl.responses.length !== 0 ||
                  subControl.responses !== null
                ) {
                  for (let i = 0; i < subControl.responses.length; i++) {
                    if (
                      subControl.responses[i].response &&
                      subControl.responses[i].response !== null &&
                      subControl.responses[i].response !== 3
                    ) {
                      if (this.controlGapTagFilter !== null) {
                        if (
                          subControl.responses[i].tags.includes(
                            this.controlGapTagFilter
                          )
                        ) {
                          totalValue += subControl.responses[i].response_value;
                        }
                      } else {
                        totalValue += subControl.responses[i].response_value;
                      }
                    }

                    if (subControl.responses[i].response != 3) {
                      if (this.controlGapTagFilter !== null) {
                        if (
                          subControl.responses[i].tags.includes(
                            this.controlGapTagFilter
                          )
                        ) {
                          totalQs++;
                        }
                      } else {
                        totalQs++;
                      }
                    }
                  }
                }
              });
            }
            // this.responseValuesControl = totalValue;
          });
        }

        if (totalValue == 0) {
          return 0;
        }

        return parseFloat(totalValue / totalQs);
      }
    },

    getGroupCompliance(response) {
      let totalResponseValue = 0;
      let totalQsNo = 0;
      console.log("Group Compliance", response);

      // Group Level Questions Looping
      if (
        response.responses &&
        response.responses != null &&
        Array.isArray(response.responses) &&
        response.responses.length > 0
      ) {
        response.responses.forEach((question) => {
          // Calculating total Response Value
          if (
            question.response &&
            question.response !== null &&
            question.response !== 3
          ) {
            if (this.controlGapTagFilter !== null) {
              if (question.tags.includes(this.controlGapTagFilter)) {
                totalResponseValue += question.response_value;
              }
            } else {
              totalResponseValue += question.response_value;
            }
          }

          // Calculating total number of Questions
          if (question.response !== 3) {
            if (this.controlGapTagFilter !== null) {
              if (question.tags.includes(this.controlGapTagFilter)) {
                totalQsNo++;
              }
            } else {
              totalQsNo++;
            }
          }
        });

        // for (let i = 0; i < response.responses.length; i++) {
        //   if (
        //     response.responses[i].response &&
        //     response.responses[i].response !== null &&
        //     response.responses[i].response !== 3
        //   ) {
        //     if (this.controlGapTagFilter !== null) {
        //       if (
        //         response.responses[i].tags.includes(this.controlGapTagFilter)
        //       ) {
        //         totalResponseValue += response.responses[i].response_value;
        //       }
        //     } else {
        //       totalResponseValue += response.responses[i].response_value;
        //     }
        //   }
        //   if (response.responses[i].response !== 3) {
        //     if (this.controlGapTagFilter !== null) {
        //       if (
        //         response.responses[i].tags.includes(this.controlGapTagFilter)
        //       ) {
        //         totalQsNo++;
        //       }
        //     } else {
        //       totalQsNo++;
        //     }
        //   }
        // }
      }
      // Control level questions looping
      if (
        response.controls &&
        response.controls != null &&
        Array.isArray(response.controls) &&
        response.controls.length > 0
      ) {
        let newControls = response.controls;
        newControls.forEach((control) => {
          control.responses.forEach((question) => {
            if (
              question.response &&
              question.response !== null &&
              question.response !== 3
            ) {
              if (this.controlGapTagFilter !== null) {
                if (question.tags.includes(this.controlGapTagFilter)) {
                  totalResponseValue += question.response_value;
                }
              } else {
                totalResponseValue += question.response_value;
              }
            }
            if (question.response !== 3) {
              if (this.controlGapTagFilter !== null) {
                if (question.tags.includes(this.controlGapTagFilter)) {
                  totalQsNo++;
                }
              } else {
                totalQsNo++;
              }
            }
          });

          // for (let i = 0; i < control.responses.length; i++) {
          //   if (
          //     control.responses[i].response &&
          //     control.responses[i].response !== null &&
          //     control.responses[i].response !== 3
          //   ) {
          //     if (this.controlGapTagFilter !== null) {
          //       if (
          //         control.responses[i].tags.includes(this.controlGapTagFilter)
          //       ) {
          //         totalResponseValue += control.responses[i].response_value;
          //       }
          //     } else {
          //       totalResponseValue += control.responses[i].response_value;
          //     }
          //   }
          //   if (control.responses[i].response !== 3) {
          //     if (this.controlGapTagFilter !== null) {
          //       if (
          //         control.responses[i].tags.includes(this.controlGapTagFilter)
          //       ) {
          //         totalQsNo++;
          //       }
          //     } else {
          //       totalQsNo++;
          //     }
          //   }
          // }

          if (
            control.subcontrols &&
            typeof control.subcontrols !== "undefined" &&
            control.subcontrols != null &&
            Array.isArray(control.subcontrols) &&
            control.subcontrols.length > 0
          ) {
            let subControls = control.subcontrols;
            // Looping through each subcontrol responses
            subControls.forEach((subControl) => {
              subControl.responses.forEach((question) => {
                if (
                  question.response &&
                  question.response !== null &&
                  question.response !== 3
                ) {
                  if (this.controlGapTagFilter !== null) {
                    if (question.tags.includes(this.controlGapTagFilter)) {
                      totalResponseValue += question.response_value;
                    }
                  } else {
                    totalResponseValue += question.response_value;
                  }
                }
                // Calculating total number of Questions
                if (question.response !== 3) {
                  if (this.controlGapTagFilter !== null) {
                    if (question.tags.includes(this.controlGapTagFilter)) {
                      totalQsNo++;
                    }
                  } else {
                    totalQsNo++;
                  }
                }
              });
            });
          }

          // if (
          //   (control.subcontrols && control.subcontrols.length !== 0) ||
          //   control.subcontrols !== null
          // ) {
          //   let newControls = control.subcontrols ? control.subcontrols : [];
          //   newControls.map((subcontrol) => {
          //     for (let i = 0; i < subcontrol.responses.length; i++) {
          //       if (
          //         subcontrol.responses[i].response &&
          //         subcontrol.responses[i].response !== null &&
          //         subcontrol.responses[i].response !== 3
          //       ) {
          //         if (this.controlGapTagFilter !== null) {
          //           if (
          //             subcontrol.responses[i].tags.includes(
          //               this.controlGapTagFilter
          //             )
          //           ) {
          //             totalResponseValue +=
          //               subcontrol.responses[i].response_value;
          //           }
          //         } else {
          //           totalResponseValue +=
          //             subcontrol.responses[i].response_value;
          //         }
          //       }
          //       if (subcontrol.responses[i].response !== 3) {
          //         if (this.controlGapTagFilter !== null) {
          //           if (
          //             subcontrol.responses[i].tags.includes(
          //               this.controlGapTagFilter
          //             )
          //           ) {
          //             totalQsNo++;
          //           }
          //         } else {
          //           totalQsNo++;
          //         }
          //       }
          //     }
          //   });
          // }
        });
      }
      //subcontrol total

      if (totalResponseValue == 0) {
        return 0;
      }

      // Calculate compliance ratio
      const complianceRatio = totalResponseValue / totalQsNo;
      return parseFloat(complianceRatio.toFixed(2));
    },

    getControlCompliance(response) {
      console.log("Control Compliance", response);
      let totalResponseValue = 0;
      let totalQsNo = 0;

      // Control Level Questions Looping
      if (
        response.responses &&
        response.responses != null &&
        Array.isArray(response.responses) &&
        response.responses.length > 0
      ) {
        response.responses.forEach((question) => {
          // Calculating total Response Value
          if (
            question.response &&
            question.response !== null &&
            question.response !== 3
          ) {
            if (this.controlGapTagFilter !== null) {
              if (question.tags.includes(this.controlGapTagFilter)) {
                totalResponseValue += question.response_value;
              }
            } else {
              totalResponseValue += question.response_value;
            }
          }

          // Calculating total number of Questions
          if (question.response !== 3) {
            if (this.controlGapTagFilter !== null) {
              if (question.tags.includes(this.controlGapTagFilter)) {
                totalQsNo++;
              }
            } else {
              totalQsNo++;
            }
          }
        });

        // for (let i = 0; i < response.responses.length; i++) {
        //   if (
        //     response.responses[i].response &&
        //     response.responses[i].response !== null &&
        //     response.responses[i].response !== 3
        //   ) {
        //     if (this.controlGapTagFilter !== null) {
        //       if (
        //         response.responses[i].tags.includes(this.controlGapTagFilter)
        //       ) {
        //         totalResponseValue += response.responses[i].response_value;
        //       }
        //     } else {
        //       totalResponseValue += response.responses[i].response_value;
        //     }
        //   }
        //   if (response.responses[i].response !== 3) {
        //     if (this.controlGapTagFilter !== null) {
        //       if (
        //         response.responses[i].tags.includes(this.controlGapTagFilter)
        //       ) {
        //         totalQsNo++;
        //       }
        //     } else {
        //       totalQsNo++;
        //     }
        //   }
        // }
      }

      // Sub-Control Level Questions Looping
      if (
        response.subcontrols &&
        typeof response.subcontrols !== "undefined" &&
        response.subcontrols != null &&
        Array.isArray(response.subcontrols) &&
        response.subcontrols.length > 0
      ) {
        let subControls = response.subcontrols;
        // Looping through each subcontrol responses
        subControls.forEach((subControl) => {
          subControl.responses.forEach((question) => {
            if (
              question.response &&
              question.response !== null &&
              question.response !== 3
            ) {
              if (this.controlGapTagFilter !== null) {
                if (question.tags.includes(this.controlGapTagFilter)) {
                  totalResponseValue += question.response_value;
                }
              } else {
                totalResponseValue += question.response_value;
              }
            }
            // Calculating total number of Questions
            if (question.response !== 3) {
              if (this.controlGapTagFilter !== null) {
                if (question.tags.includes(this.controlGapTagFilter)) {
                  totalQsNo++;
                }
              } else {
                totalQsNo++;
              }
            }
          });

          // for (let i = 0; i < subControl.responses.length; i++) {
          //   if (
          //     subControl.responses[i].response &&
          //     subControl.responses[i].response !== null &&
          //     subControl.responses[i].response !== 3
          //   ) {
          //     if (this.controlGapTagFilter !== null) {
          //       if (
          //         subControl.responses[i].tags.includes(
          //           this.controlGapTagFilter
          //         )
          //       ) {
          //         totalResponseValue += subControl.responses[i].response_value;
          //       }
          //     } else {
          //       totalResponseValue += subControl.responses[i].response_value;
          //     }
          //   }
          //   if (subControl.responses[i].response !== 3) {
          //     if (this.controlGapTagFilter !== null) {
          //       if (
          //         subControl.responses[i].tags.includes(
          //           this.controlGapTagFilter
          //         )
          //       ) {
          //         totalQsNo++;
          //       }
          //     } else {
          //       totalQsNo++;
          //     }
          //   }
          // }
        });
      }
      //subcontrol total

      if (totalResponseValue == 0) {
        return 0;
      }

      // Calculate compliance ratio
      const complianceRatio = totalResponseValue / totalQsNo;
      return parseFloat(complianceRatio.toFixed(2));
    },

    checkIfAllResponsesAreEmpty(parentResponse) {
      let responseList = [];
      if (parentResponse.type == "Group") {
        parentResponse.responses.map((response) => {
          if (
            this.checkEvidenceStatusFilter(response) &&
            this.checkResponseTypeFilter(response) &&
            this.checkTagFilter(response)
          ) {
            responseList.push(response);
          }
        });
        if (parentResponse.controls.length > 0) {
          parentResponse.controls.map((control) => {
            control.responses.map((response) => {
              if (
                this.checkEvidenceStatusFilter(response) &&
                this.checkResponseTypeFilter(response) &&
                this.checkTagFilter(response)
              ) {
                responseList.push(response);
              }
            });

            if (control.subcontrols.length > 0) {
              control.subcontrols.map((subcontrol) => {
                subcontrol.responses.map((response) => {
                  if (
                    this.checkEvidenceStatusFilter(response) &&
                    this.checkResponseTypeFilter(response) &&
                    this.checkTagFilter(response)
                  ) {
                    responseList.push(response);
                  }
                });
              });
            }
          });
        }
      } else if (parentResponse.type == "Control") {
        parentResponse.responses.map((response) => {
          if (
            this.checkEvidenceStatusFilter(response) &&
            this.checkResponseTypeFilter(response) &&
            this.checkTagFilter(response)
          ) {
            responseList.push(response);
          }
        });
        if (parentResponse.subcontrols.length > 0) {
          parentResponse.subcontrols.map((subcontrol) => {
            subcontrol.responses.map((response) => {
              if (
                this.checkEvidenceStatusFilter(response) &&
                this.checkResponseTypeFilter(response) &&
                this.checkTagFilter(response)
              ) {
                responseList.push(response);
              }
            });
          });
        }
      }

      if (responseList.length > 0) {
        return false;
      } else {
        return true;
      }
    },

    // Function to check if the Group/Control/Sub-Control Response matches the Evidence Status and response filter
    checkResponseMatchFilters(parentResponse) {
      //Loops through every response of the Group/Control/Sub-Control to check condition
      let newResponses = parentResponse.responses.filter((response) => {
        if (
          //Checks if the response of the Group/Control/Sub-Control matches the Evidence Status and response filter
          this.checkEvidenceStatusFilter(response) &&
          this.checkResponseTypeFilter(response) &&
          this.checkTagFilter(response)
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (newResponses.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    checkEvidenceStatusFilter(response) {
      if (
        (this.controlGapEvidenceStatusFilter &&
          this.controlGapEvidenceStatusFilter !== null) ||
        this.controlGapEvidenceStatusFilter == 0
      ) {
        if (this.controlGapEvidenceStatusFilter == response.evidence_status) {
          return true;
        } else if (
          this.controlGapEvidenceStatusFilter === 0 &&
          response.evidence_status == null
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    checkResponseTypeFilter(response) {
      if (
        (this.controlGapResponseFilter &&
          this.controlGapResponseFilter !== null) ||
        this.controlGapResponseFilter == 0
      ) {
        if (this.controlGapResponseFilter == response.response_value) {
          return true;
        } else if (
          this.controlGapResponseFilter === -2 &&
          response.response_value == null
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkTagFilter(response) {
      if (this.controlGapTagFilter && this.controlGapTagFilter != null) {
        if (
          response.tags.length > 0 &&
          response.tags.includes(this.controlGapTagFilter)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    handleResponseComplianceShow(response) {
      if (this.controlGapFilter == 1) {
        if (
          this.getResponseComplianceStatus(response._id) == 1 &&
          this.getTotalResponseAssessedQuestions(response._id) ==
            this.getTotalResponseQuestions(response._id)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.controlGapFilter == 0) {
        if (
          this.getResponseComplianceStatus(response._id) == 1 &&
          this.getTotalResponseAssessedQuestions(response._id) ==
            this.getTotalResponseQuestions(response._id)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    //Control Compliance Filter
    handleControlShow(control) {
      if (this.controlGapFilter == 1) {
        if (
          this.getControlGapStatus(control._id) == 1 &&
          this.getTotalControlAssessedQuestions(control._id) ==
            this.getTotalControlQuestions(control._id)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (this.controlGapFilter == 0) {
        if (
          this.getControlGapStatus(control._id) == 1 &&
          this.getTotalControlAssessedQuestions(control._id) ==
            this.getTotalControlQuestions(control._id)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    searchPhraseInControlQuestions(phrase, control) {
      if (
        control.responses.filter((response) =>
          response.question.question.toLowerCase().includes(phrase)
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },

    searchPhraseInSubControlName(phrase, control) {
      if (
        control.subcontrols.filter((subcontrol) =>
          subcontrol.name.toLowerCase().includes(phrase)
        ).length > 0
      ) {
        return true;
      } else return false;
    },

    searchPhraseInSubControlNumber(phrase, control) {
      if (
        control.subcontrols.filter((subcontrol) =>
          subcontrol.control_no.toLowerCase().includes(phrase)
        ).length > 0
      ) {
        return true;
      } else return false;
    },

    searchPhraseInSubControlQuestions(phrase, control) {
      if (
        control.subcontrols.filter((subcontrol) => {
          subcontrol.responses.filter((response) =>
            response.question.question.toLowerCase().includes(phrase)
          ).length > 0
            ? true
            : false;
        }).length > 0
      ) {
        return true;
      } else return false;
    },

    handleFiltersReset() {
      this.filters.searchPhrase = "";
      this.controlGapFilter = null;
      this.controls = this.allControls;
    },

    resetSelectedGap() {
      this.selectedGap.observation = null;
      this.selectedGap.isCompensatoryControlsUsed = false;
      this.selectedGap.compensatory_compliance_note = null;
      this.selectedGap.response_value = null;
      this.selectedGap.evidenceFiles = null;
      this.selectedGap.evidenceResponsibleUser = null;
      this.selectedGap.recommendation = null;
      this.selectedGap.taskDescription = null;
      this.selectedGap.taskName = null;
      this.selectedGap.notifyTaskAssignee = false;
      this.selectedGap.dueDate = null;
      // this.selectedGap.gapAssessmentId = null;
      // this.selectedGap.gapAssessmentResponseId = null;
    },

    handleControlHover(controlId) {
      this.activeControlId = controlId;
    },

    handleGapAssessmentBulkUploadClick(gapAssessmentId) {
      this.bulkUploadGapId = gapAssessmentId;
      this.activeFamilyId = null;
    },

    gapAssessmentEvidenceBulkUploadAndSetData(evidenceFiles) {
      this.gapAssessmentEvidenceBulkUpload({
        gapAssessmentId: this.bulkUploadGapId,
        evidenceFiles: evidenceFiles,
      })
        .then((res) => {
          this.handleResponse(res);
          this.bulkUploadedEvidences = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    getGapAssessmentEvidenceFilesAndSetData(gapAssessmentId) {
      this.getGapAssessmentEvidenceFiles(gapAssessmentId, { limit: 100 })
        .then((res) => {
          this.bulkUploadedEvidences = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getResponseValue(num) {
      if (num !== null || num == 0) {
        const setIndex = this.responseSets.findIndex((set) => set.value == num);
        if (setIndex != -1) {
          return this.responseSets[setIndex].title;
        }
      }
    },

    getStatusValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Evidence Requested";
          case 2:
            return "Uploaded";
          case 3:
            return "Evidence Approved";
          case 4:
            return "Rejected";
        }
      }
    },

    getCurrentUserDetailsAndSetData() {
      this.showOverlay = true;
      this.getCurrentUserDetails()
        .then((res) => {
          this.currentUser = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleQuestionClick(obj) {
      if (this.$route.query && this.$route.query.control_family_id) {
        this.$router.replace({ query: null });
      }
      this.selectedGap.gapAssessmentId = this.$route.params.id;
      this.selectedResponse = obj.response;
      this.selectedGap.response_value = obj.response.response_value;
      this.selectedGap.observation = obj.response.observation;
      if (
        obj.response.is_compensatory_controls_used &&
        obj.response.is_compensatory_controls_used !== null
      ) {
        this.selectedGap.isCompensatoryControlsUsed =
          obj.response.is_compensatory_controls_used == 1 ? true : false;
        this.selectedGap.compensatory_compliance_note =
          obj.response.compensatory_controls_note;
      }
      this.selectedGap.recommendation = obj.response.recommendation;
      this.selectedGap.evidenceFiles = null;
      this.openSidebar();
    },

    getGapCompletedPercentage(total, notApplicableCount, existCount) {
      let gapPercentage = 100 - existCount / (total - notApplicableCount) / 100;
      return gapPercentage;
    },

    getFamilyName(id) {
      const familyIndex = this.standard.control_families.findIndex(
        (fam) => fam._id === id
      );
      if (familyIndex !== -1) {
        return this.standard.control_families[familyIndex].name;
      }
    },

    getGapAssessmentDetailsAndSetData(id) {
      this.showOverlay = true;
      this.show = true;
      this.getGapDetails(id)
        .then((res) => {
          const gapData = res.data.data;
          this.$emit('gap-updated',gapData.standard)
          this.gap = gapData;
          this.all_tags = gapData.tags ? gapData.tags : [];
          this.responseSets = gapData.standard.response_set;
          this.tag_options = [];
          this.all_tags.forEach((item) => {
            const a = {
              text: item,
              value: item,
            };
            this.tag_options.push(a);
          });
          this.chartList = [];

          this.chartList.push({
            title: "Gap",
            value: Number(gapData.gap.toFixed(1)),
            colorsReversed: false,
          });
          this.chartList.push({
            title: "Progress",
            value: Number(Number(gapData.completion).toFixed(1)),
            colorsReversed: true,
          });
          this.getStandardDetailsAndSetData(gapData.standard_id);
          this.show = true;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    updateGapAssessmentResponsesFromResponse(res) {
      const gapIndex = this.chartList.findIndex((item) => item.title == "Gap");
      const progressIndex = this.chartList.findIndex(
        (item) => item.title == "Progress"
      );
      this.chartList[gapIndex].value = Number(
        res.data.data.assessment.gap.toFixed(1)
      );
      this.chartList[progressIndex].value = Number(
        res.data.data.assessment.completion.toFixed(1)
      );
      let newResponse = res.data.data;
      // if (newResponse.response !== null) {
      //   if (newResponse.question.always_applicable != "1") {
      //     newResponse["options"] = [
      //       { value: 1, text: "Yes" },
      //       { value: 2, text: "No" },
      //       { value: 3, text: "Not Applicable" },
      //     ];
      //   } else {
      //     newResponse["options"] = [
      //       { value: 1, text: "Yes" },
      //       { value: 2, text: "No" },
      //     ];
      //   }
      // } else {
      //   if (newResponse.question.always_applicable != "1") {
      //     newResponse["options"] = [
      //       { value: null, text: "Select an Option" },
      //       { value: 1, text: "Yes" },
      //       { value: 2, text: "No" },
      //       { value: 3, text: "Not Applicable" },
      //     ];
      //   } else {
      //     newResponse["options"] = [
      //       { value: null, text: "Select an Option" },
      //       { value: 1, text: "Yes" },
      //       { value: 2, text: "No" },
      //     ];
      //   }
      // }

      if (typeof newResponse.response != "undefined") {
        if (
          this.standard &&
          this.standard !== null &&
          this.standard.response_set &&
          this.standard.response_set !== null
        ) {
          let newOptions = this.standard.response_set.map((option) => {
            let newOption = { value: option.value, text: option.title };
            return newOption;
          });
          if (newResponse.question.always_applicable != "1") {
            newOptions.push({
              value: -1,
              text: "Not Applicable",
            });
          }
          newOptions.push({ text: "--Select An Option--", value: null });
          newResponse["options"] = newOptions;
        }
      }

      //Finding the item inside the responses
      let newResponses = this.responses;
      newResponses = newResponses.map((response) => {
        //IF the Response is a group
        if (response.type == "Group") {
          //Responses inside Group
          let groupItems = response.responses.map((item) => {
            if (item._id == newResponse._id) {
              return newResponse;
            } else {
              return item;
            }
          });
          response.responses = groupItems;
          //Controls inside Group
          let groupControls = response.controls.map((control) => {
            //Responses inside Group Control
            let GroupControlResponses = control.responses.map((item) => {
              if (item._id == newResponse._id) {
                return newResponse;
              } else {
                return item;
              }
            });
            control.responses = GroupControlResponses;
            // Subcontrols inside Group Control
            let GroupControlSubcontrols = control.subcontrols.map(
              (subcontrol) => {
                //Responses inside Group Control Subcontrol
                let GroupControlSubcontrolResponses = subcontrol.responses.map(
                  (item) => {
                    if (item._id == newResponse._id) {
                      return newResponse;
                    } else {
                      return item;
                    }
                  }
                );
                subcontrol.responses = GroupControlSubcontrolResponses;
                return subcontrol;
              }
            );
            control.subcontrols = GroupControlSubcontrols;
            return control;
          });
          response.controls = groupControls;
          return response;
        } else if (response.type == "Control") {
          // Responses inside Control
          let controlResponses = response.responses.map((item) => {
            if (item._id == newResponse._id) {
              return newResponse;
            } else {
              return item;
            }
          });
          response.responses = controlResponses;
          // Subcontrols inside Control
          let controlSubcontrols = response.subcontrols.map((subcontrol) => {
            //Responses inside Control Subcontrol
            let controlSubcontrolResponses = subcontrol.responses.map(
              (item) => {
                if (item._id == newResponse._id) {
                  return newResponse;
                } else {
                  return item;
                }
              }
            );
            subcontrol.responses = controlSubcontrolResponses;
            return subcontrol;
          });
          response.subcontrols = controlSubcontrols;
          return response;
        }
      });
      this.responses = newResponses;
    },

    updateGapAssessmentResponsesAndSetData(obj) {
      this.showOverlay = true;
      this.updateGapAssessmentResponses(obj)
        .then((res) => {
          this.updateGapAssessmentResponsesFromResponse(res);
          this.resetSelectedGap();
          this.closeSidebar();
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getStandardDetailsAndSetData(id) {
      this.show = true;
      this.getStandardDetails(id)
        .then((res) => {
          const standardData = res.data.data;
          this.standard = standardData;
          //Setting Options for Filters based on Response
          this.standard.response_set.map((response) => {
            const option = { text: response.title, value: response.value };
            this.controlGapResponseFilterOptions.push(option);
          });
          if (this.activeFamilyId === null && this.activeGroupId === null) {
            const query = this.$route.query;
            if (query && query.control_family_id) {
              this.activeFamilyId = query.control_family_id;
            } else {
              this.activeFamilyId = standardData.control_families[0]._id;
            }
          } else if (
            this.activeFamilyId !== null &&
            this.activeGroupId === null
          ) {
            // this.getGapControlsAndSetData(this.$route.params.id, {
            //   control_family_id: this.activeFamilyId,
            // });
            this.getGapAssessmentResponsesAndSetData(this.$route.params.id, {
              control_family_id: this.activeFamilyId,
            });
          } else if (
            this.activeFamilyId !== null &&
            this.activeGroupId !== null
          ) {
            // this.getGapControlsAndSetData(this.$route.params.id, {
            //   control_group_id: this.activeGroupId,
            // });
            this.getGapAssessmentResponsesAndSetData(this.$route.params.id, {
              control_group_id: this.activeGroupId,
            });
          }
          this.show = false;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleFamilyClick(id) {
      //Reset the filters
      this.filters.searchPhrase = "";

      if (this.$route.query && this.$route.query.control_family_id) {
        this.$router.replace({ query: null });
      }
      this.bulkUploadGapId = null;

      if (id === this.activeFamilyId) {
        this.activeGroupId = null;
        // this.getGapControlsAndSetData(this.$route.params.id, {
        //   control_family_id: id,
        // });
        this.getGapAssessmentResponsesAndSetData(this.$route.params.id, {
          control_family_id: id,
        });
      } else {
        this.activeGroupId = null;
        this.activeFamilyId = id;
      }
    },

    handleGroupClick(familyId, groupId) {
      if (this.$route.query && this.$route.query.control_family_id) {
        this.$router.replace({ query: null });
      }
      this.activeFamilyId = familyId;
      this.activeGroupId = groupId;
      this.bulkUploadGapId = null;
    },

    percentage(partialValue, totalValue) {
      return Math.round(((100 * partialValue) / totalValue) * 10) / 10;
    },

    getControlGapPercentage(controlId) {
      const controlIndex = this.responses.findIndex(
        (control) => control._id === controlId
      );
      let newControl = this.responses[controlIndex];
      let controlResponses = [];

      if (newControl.responses.length !== 0 || newControl.responses !== null) {
        for (let i = 0; i < newControl.responses.length; i++) {
          controlResponses.push({
            id: newControl.responses[i]._id,
            response: newControl.responses[i].response,
          });
        }
      }

      if (
        newControl.subcontrols.length !== 0 ||
        newControl.subcontrols !== null
      ) {
        let newSubcontrols = newControl.subcontrols;
        newSubcontrols.map((subControl) => {
          if (
            subControl.responses.length !== 0 ||
            subControl.responses !== null
          ) {
            for (let i = 0; i < subControl.responses.length; i++) {
              controlResponses.push({
                id: subControl.responses[i]._id,
                response: subControl.responses[i].response,
              });
            }
          }
        });
      }
      return controlResponses.length;
    },

    getTotalControlQuestions(controlId) {
      const controlIndex = this.responses.findIndex(
        (control) => control._id === controlId
      );
      let newControl = this.responses[controlIndex];
      let controlResponses = [];

      if (newControl.responses.length !== 0 || newControl.responses !== null) {
        for (let i = 0; i < newControl.responses.length; i++) {
          controlResponses.push({
            id: newControl.responses[i]._id,
            response: newControl.responses[i].response,
          });
        }
      }

      if (
        newControl.subcontrols.length !== 0 ||
        newControl.subcontrols !== null
      ) {
        let newSubcontrols = newControl.subcontrols;
        newSubcontrols.map((subControl) => {
          if (
            subControl.responses.length !== 0 ||
            subControl.responses !== null
          ) {
            for (let i = 0; i < subControl.responses.length; i++) {
              controlResponses.push({
                id: subControl.responses[i]._id,
                response: subControl.responses[i].response,
              });
            }
          }
        });
      }
      return controlResponses.length;
    },

    getTotalControlAssessedQuestions(controlId) {
      const controlIndex = this.responses.findIndex(
        (control) => control._id === controlId
      );
      let newControl = this.responses[controlIndex];
      let controlResponses = [];

      if (newControl.responses.length !== 0 || newControl.responses !== null) {
        for (let i = 0; i < newControl.responses.length; i++) {
          if (
            newControl.responses[i].response &&
            newControl.responses[i].response !== null
          ) {
            controlResponses.push({
              id: newControl.responses[i]._id,
              response: newControl.responses[i].response,
            });
          }
        }
      }

      if (
        newControl.subcontrols.length !== 0 ||
        newControl.subcontrols !== null
      ) {
        let newSubcontrols = newControl.subcontrols;
        newSubcontrols.map((subControl) => {
          if (
            subControl.responses.length !== 0 ||
            subControl.responses !== null
          ) {
            for (let i = 0; i < subControl.responses.length; i++) {
              if (
                subControl.responses[i].response &&
                subControl.responses[i].response !== null
              ) {
                controlResponses.push({
                  id: subControl.responses[i]._id,
                  response: subControl.responses[i].response,
                });
              }
            }
          }
        });
      }
      return controlResponses.length;
    },

    getControlGapStatus(controlId) {
      const controlIndex = this.responses.findIndex(
        (control) => control._id === controlId
      );
      let newControl = this.responses[controlIndex];

      if (
        newControl.responses &&
        newControl.responses.length !== 0 &&
        newControl.responses !== null
      ) {
        for (let i = 0; i < newControl.responses.length; i++) {
          if (
            newControl.responses[i].response &&
            newControl.responses[i].response !== null
          ) {
            if (
              newControl.responses[i].response ==
              (newControl.responses[i].question.answer == 0 ? 2 : 1)
            ) {
              // console.log(
              //   `Response:${newControl.responses[i].response} === Answer:${
              //     newControl.responses[i].question.answer == 0 ? 2 : 1
              //   }`
              // );
              return 0;
            }
            // else {
            //   console.log(
            //     `Response:${newControl.responses[i].response} === Answer:${
            //       newControl.responses[i].question.answer == 0 ? 2 : 1
            //     }`
            //   );
            // }
          }
        }
      }

      if (
        newControl.subcontrols.length !== 0 ||
        newControl.subcontrols !== null
      ) {
        let newSubcontrols = newControl.subcontrols;
        newSubcontrols.map((subControl) => {
          if (
            subControl.responses.length !== 0 ||
            subControl.responses !== null
          ) {
            for (let i = 0; i < subControl.responses.length; i++) {
              if (
                subControl.responses[i].response &&
                subControl.responses[i].response !== null
              ) {
                if (
                  subControl.responses[i].response ==
                  (subControl.responses[i].question.answer == 0 ? 2 : 1)
                ) {
                  // console.log(
                  //   `Response:${subControl.responses[i].response} === Answer:${
                  //     subControl.responses[i].question.answer == 0 ? 2 : 1
                  //   }`
                  // );
                  return 0;
                }
                // else {
                //   console.log(
                //     `Response:${subControl.responses[i].response} === Answer:${
                //       subControl.responses[i].question.answer == 0 ? 2 : 1
                //     }`
                //   );
                // }
              }
            }
          }
        });
      }
      return 1;
    },

    getGapAssessmentResponsesAndSetData(gapAssessmentId, params) {
      this.showOverlay = true;
      this.getGapAssessmentResponses(gapAssessmentId, params)
        .then((res) => {
          let newResponses = res.data.data;
          newResponses = newResponses.map((response) => {
            //If The Parent is Group
            if (response.type && response.type == "Group") {
              if (
                response.responses &&
                response.responses.length > 0 &&
                response.responses !== null
              ) {
                let newGroups = response.responses.map((response) => {
                  //Inserting Options Object for Question Assessment
                  // if (response.response !== null) {
                  //   if (response.question.always_applicable != "1") {
                  //     response["options"] = [
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //       { value: 3, text: "Not Applicable" },
                  //     ];
                  //   } else {
                  //     response["options"] = [
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //     ];
                  //   }
                  // } else {
                  //   if (response.question.always_applicable != "1") {
                  //     response["options"] = [
                  //       { value: null, text: "Select an Option" },
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //       { value: 3, text: "Not Applicable" },
                  //     ];
                  //   } else {
                  //     response["options"] = [
                  //       { value: null, text: "Select an Option" },
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //     ];
                  //   }
                  // }
                  if (typeof response.response != "undefined") {
                    if (
                      this.standard &&
                      this.standard !== null &&
                      this.standard.response_set &&
                      this.standard.response_set !== null
                    ) {
                      let newOptions = this.standard.response_set.map(
                        (option) => {
                          let newOption = {
                            value: option.value,
                            text: option.title,
                          };
                          return newOption;
                        }
                      );
                      if (response.question.always_applicable != "1") {
                        newOptions.push({
                          value: -1,
                          text: "Not Applicable",
                        });
                      }
                      newOptions.push({
                        value: null,
                        text: "--Select An Option--",
                      });
                      response["options"] = newOptions;
                    }
                  }
                  //Used to Show the assessment form when the assessment id exist in the route
                  if (
                    response._id ===
                    this.$route.query.gap_assessment_response_id
                  ) {
                    this.selectedResponse = response;
                  }
                  return response;
                });
                response.responses = newGroups;
              }

              if (
                response.controls &&
                response.controls !== null &&
                response.controls.length > 0
              ) {
                let newControls = response.controls.map((response) => {
                  //Control Mapping
                  if (
                    response.responses &&
                    response.responses.length > 0 &&
                    response.responses !== null
                  ) {
                    let newControls = response.responses.map((response) => {
                      //Inserting Options Object for Question Assessment
                      // if (response.response !== null) {
                      //   if (response.question.always_applicable != "1") {
                      //     response["options"] = [
                      //       { value: 1, text: "Yes" },
                      //       { value: 2, text: "No" },
                      //       { value: 3, text: "Not Applicable" },
                      //     ];
                      //   } else {
                      //     response["options"] = [
                      //       { value: 1, text: "Yes" },
                      //       { value: 2, text: "No" },
                      //     ];
                      //   }
                      // } else {
                      //   if (response.question.always_applicable != "1") {
                      //     response["options"] = [
                      //       { value: null, text: "Select an Option" },
                      //       { value: 1, text: "Yes" },
                      //       { value: 2, text: "No" },
                      //       { value: 3, text: "Not Applicable" },
                      //     ];
                      //   } else {
                      //     response["options"] = [
                      //       { value: null, text: "Select an Option" },
                      //       { value: 1, text: "Yes" },
                      //       { value: 2, text: "No" },
                      //     ];
                      //   }
                      // }

                      if (typeof response.response != "undefined") {
                        if (
                          this.standard &&
                          this.standard !== null &&
                          this.standard.response_set &&
                          this.standard.response_set !== null
                        ) {
                          let newOptions = this.standard.response_set.map(
                            (option) => {
                              let newOption = {
                                value: option.value,
                                text: option.title,
                              };
                              return newOption;
                            }
                          );
                          if (response.question.always_applicable != "1") {
                            newOptions.push({
                              value: -1,
                              text: "Not Applicable",
                            });
                          }
                          newOptions.push({
                            value: null,
                            text: "--Select An Option--",
                          });
                          response["options"] = newOptions;
                        }
                      }

                      //Used to Show the assessment form when the assessment id exist in the route
                      if (
                        response._id ===
                        this.$route.query.gap_assessment_response_id
                      ) {
                        this.selectedResponse = response;
                      }
                      return response;
                    });
                    response.responses = newControls;
                  }
                  //Sub-Control Mapping
                  if (
                    response.subcontrols &&
                    response.subcontrols.length > 0 &&
                    response.subcontrols !== null
                  ) {
                    let newSubcontrols = response.subcontrols;
                    newSubcontrols = newSubcontrols.map((subcontrol) => {
                      let newResponses = subcontrol.responses;
                      newResponses = newResponses.map((response) => {
                        // if (response.response !== null) {
                        //   if (response.question.always_applicable != "1") {
                        //     response["options"] = [
                        //       { value: 1, text: "Yes" },
                        //       { value: 2, text: "No" },
                        //       { value: 3, text: "Not Applicable" },
                        //     ];
                        //   } else {
                        //     response["options"] = [
                        //       { value: 1, text: "Yes" },
                        //       { value: 2, text: "No" },
                        //     ];
                        //   }
                        // } else {
                        //   if (response.question.always_applicable != "1") {
                        //     response["options"] = [
                        //       { value: null, text: "Select an Option" },
                        //       { value: 1, text: "Yes" },
                        //       { value: 2, text: "No" },
                        //       { value: 3, text: "Not Applicable" },
                        //     ];
                        //   } else {
                        //     response["options"] = [
                        //       { value: null, text: "Select an Option" },
                        //       { value: 1, text: "Yes" },
                        //       { value: 2, text: "No" },
                        //     ];
                        //   }
                        // }
                        if (typeof response.response != "undefined") {
                          if (
                            this.standard &&
                            this.standard !== null &&
                            this.standard.response_set &&
                            this.standard.response_set !== null
                          ) {
                            let newOptions = this.standard.response_set.map(
                              (option) => {
                                let newOption = {
                                  value: option.value,
                                  text: option.title,
                                };
                                return newOption;
                              }
                            );
                            if (response.question.always_applicable != "1") {
                              newOptions.push({
                                value: -1,
                                text: "Not Applicable",
                              });
                            }

                            newOptions.push({
                              value: null,
                              text: "--Select An Option--",
                            });
                            response["options"] = newOptions;
                          }
                        }

                        if (
                          response._id ===
                          this.$route.query.gap_assessment_response_id
                        ) {
                          this.selectedResponse = response;
                        }
                        return response;
                      });

                      subcontrol.responses = newResponses;

                      return subcontrol;
                    });
                    response.subcontrols = newSubcontrols;
                  }

                  return response;
                });
                response.controls = newControls;
              }
              return response;
            }

            //If Parent is Control
            else if (response.type && response.type == "Control") {
              //Control Mapping
              if (
                response.responses &&
                response.responses.length > 0 &&
                response.responses !== null
              ) {
                let newControls = response.responses.map((response) => {
                  //Inserting Options Object for Question Assessment
                  // if (response.response !== null) {
                  //   if (response.question.always_applicable != "1") {
                  //     response["options"] = [
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //       { value: 3, text: "Not Applicable" },
                  //     ];
                  //   } else {
                  //     response["options"] = [
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //     ];
                  //   }
                  // } else {
                  //   if (response.question.always_applicable != "1") {
                  //     response["options"] = [
                  //       { value: null, text: "Select an Option" },
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //       { value: 3, text: "Not Applicable" },
                  //     ];
                  //   } else {
                  //     response["options"] = [
                  //       { value: null, text: "Select an Option" },
                  //       { value: 1, text: "Yes" },
                  //       { value: 2, text: "No" },
                  //     ];
                  //   }
                  // }
                  if (typeof response.response != "undefined") {
                    if (
                      this.standard &&
                      this.standard !== null &&
                      this.standard.response_set &&
                      this.standard.response_set !== null
                    ) {
                      let newOptions = this.standard.response_set.map(
                        (option) => {
                          let newOption = {
                            value: option.value,
                            text: option.title,
                          };
                          return newOption;
                        }
                      );
                      if (response.question.always_applicable != "1") {
                        newOptions.push({
                          value: -1,
                          text: "Not Applicable",
                        });
                      }
                      newOptions.push({
                        value: null,
                        text: "--Select An Option--",
                      });
                      response["options"] = newOptions;
                    }
                  }
                  //Used to Show the assessment form when the assessment id exist in the route
                  if (
                    response._id ===
                    this.$route.query.gap_assessment_response_id
                  ) {
                    this.selectedResponse = response;
                  }
                  return response;
                });
                response.responses = newControls;
              }
              //Sub-Control Mapping
              if (
                response.subcontrols &&
                response.subcontrols.length > 0 &&
                response.subcontrols !== null
              ) {
                let newSubcontrols = response.subcontrols;
                newSubcontrols = newSubcontrols.map((subcontrol) => {
                  let newResponses = subcontrol.responses;
                  newResponses = newResponses.map((response) => {
                    // if (response.response !== null) {
                    //   if (response.question.always_applicable != "1") {
                    //     response["options"] = [
                    //       { value: 1, text: "Yes" },
                    //       { value: 2, text: "No" },
                    //       { value: 3, text: "Not Applicable" },
                    //     ];
                    //   } else {
                    //     response["options"] = [
                    //       { value: 1, text: "Yes" },
                    //       { value: 2, text: "No" },
                    //     ];
                    //   }
                    // } else {
                    //   if (response.question.always_applicable != "1") {
                    //     response["options"] = [
                    //       { value: null, text: "Select an Option" },
                    //       { value: 1, text: "Yes" },
                    //       { value: 2, text: "No" },
                    //       { value: 3, text: "Not Applicable" },
                    //     ];
                    //   } else {
                    //     response["options"] = [
                    //       { value: null, text: "Select an Option" },
                    //       { value: 1, text: "Yes" },
                    //       { value: 2, text: "No" },
                    //     ];
                    //   }
                    // }
                    if (typeof response.response != "undefined") {
                      if (
                        this.standard &&
                        this.standard !== null &&
                        this.standard.response_set &&
                        this.standard.response_set !== null
                      ) {
                        let newOptions = this.standard.response_set.map(
                          (option) => {
                            let newOption = {
                              value: option.value,
                              text: option.title,
                            };
                            return newOption;
                          }
                        );
                        if (response.question.always_applicable != "1") {
                          newOptions.push({
                            value: -1,
                            text: "Not Applicable",
                          });
                        }
                        newOptions.push({
                          value: null,
                          text: "--Select An Option--",
                        });
                        response["options"] = newOptions;
                      }
                    }
                    if (
                      response._id ===
                      this.$route.query.gap_assessment_response_id
                    ) {
                      this.selectedResponse = response;
                    }
                    return response;
                  });

                  subcontrol.responses = newResponses;

                  return subcontrol;
                });
                response.subcontrols = newSubcontrols;
              }

              return response;
            }
          });
          this.responses = newResponses;
          this.show = false;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
          this.show = false;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getGapControlsAndSetData(gapAssessmentId, params) {
      this.showOverlay = true;
      this.getGapAssessmentResponses(gapAssessmentId, params)
        .then((res) => {
          let newControls = res.data.data;
          newControls = newControls.map((control) => {
            // -------------- If Control --------------
            if (control.responses.length !== 0 || control.responses !== null) {
              let newResponses = control.responses;
              newResponses = newResponses.map((response) => {
                if (response.response !== null) {
                  if (response.question.always_applicable != "1") {
                    response["options"] = [
                      { value: 1, text: "Yes" },
                      { value: 2, text: "No" },
                      { value: 3, text: "Not Applicable" },
                    ];
                  } else {
                    response["options"] = [
                      { value: 1, text: "Yes" },
                      { value: 2, text: "No" },
                    ];
                  }
                } else {
                  if (response.question.always_applicable != "1") {
                    response["options"] = [
                      { value: null, text: "Select an Option" },
                      { value: 1, text: "Yes" },
                      { value: 2, text: "No" },
                      { value: 3, text: "Not Applicable" },
                    ];
                  } else {
                    response["options"] = [
                      { value: null, text: "Select an Option" },
                      { value: 1, text: "Yes" },
                      { value: 2, text: "No" },
                    ];
                  }
                }
                if (
                  response._id === this.$route.query.gap_assessment_response_id
                ) {
                  this.selectedResponse = response;
                }
                return response;
              });
              control.responses = newResponses;
            }
            // -------------- If SubControl --------------
            if (
              control.subcontrols.length !== 0 ||
              control.subcontrols !== null
            ) {
              let newSubcontrols = control.subcontrols;
              newSubcontrols = newSubcontrols.map((subcontrol) => {
                let newResponses = subcontrol.responses;
                newResponses = newResponses.map((response) => {
                  if (response.response !== null) {
                    if (response.question.always_applicable != "1") {
                      response["options"] = [
                        { value: 1, text: "Yes" },
                        { value: 2, text: "No" },
                        { value: 3, text: "Not Applicable" },
                      ];
                    } else {
                      response["options"] = [
                        { value: 1, text: "Yes" },
                        { value: 2, text: "No" },
                      ];
                    }
                  } else {
                    if (response.question.always_applicable != "1") {
                      response["options"] = [
                        { value: null, text: "Select an Option" },
                        { value: 1, text: "Yes" },
                        { value: 2, text: "No" },
                        { value: 3, text: "Not Applicable" },
                      ];
                    } else {
                      response["options"] = [
                        { value: null, text: "Select an Option" },
                        { value: 1, text: "Yes" },
                        { value: 2, text: "No" },
                      ];
                    }
                  }
                  if (
                    response._id ===
                    this.$route.query.gap_assessment_response_id
                  ) {
                    this.selectedResponse = response;
                  }
                  return response;
                });

                subcontrol.responses = newResponses;

                return subcontrol;
              });

              control.subcontrols = newSubcontrols;
            }

            return control;
          });
          this.controls = newControls;
          this.allControls = newControls;
          if (
            this.$route.query["gap_assessment_response_id"] &&
            this.selectedResponse
          ) {
            this.handleQuestionClick({ response: this.selectedResponse });
          }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeSidebar: function () {
      if (this.$route.query && this.$route.query.control_family_id) {
        this.$router.replace({ query: null });
      }
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.resetSelectedGap();
    },
    openSidebar: function () {
      // this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
    // hideModal() {
    //   this.$refs["my-modal"].hide();
    // },
    // showModal() {
    //   this.$refs["my-modal"].show();
    // },
  },
};
</script>

<style lang="scss">
.b-sidebar.sidebar-lg {
  width: 800px !important;
}
</style>

<style lang="scss" scoped>
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.icon-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 3px;
}

.sidebar-left {
  width: 25%;

  .todo-sidebar {
    width: 100%;
  }
}

.todo-bottom-section {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.bottom-area {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 200px;
}

.list-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  transition: all 0.4s ease-in-out;

  &:hover {
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    //   rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .header-section {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.dark-layout{

  .list-group .list-group-item.active small {
     color: #fff !important;
 }
}
.list-group .list-group-item.active small {
  color:#6e6b7b !important;
}


 
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
