<template>
  <div id="app">
    <treeselect
      v-model="selectedcontrol"
      :multiple="true"
      :options="options.option"
      :disable-branch-nodes="true"
    />
    <!-- {{selectedcontrol}} -->
  </div>
</template>

<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  // register the component
  components: { Treeselect },
  props: {
    cf: Array,
    selectedcontrol: Array,
  },
  data() {
    return {
      // define the default value
      selectedcontrol: null,
      // define options
      options: [],
    };
  },
  mounted() {
    this.formatTreeArray();
  },
  watch: {
    selectedcontrol() {
      this.$emit("val", this.selectedcontrol);
    },
  },
  methods: {
    formatTreeArray() {
      console.log("xyz");
      var options = {
        option: [],
      };
      this.cf.map(function (item) {
        var length = options.option.push({
          id: item._id,
          label: item.name,
        });
        options.option[length - 1]["children"] = [];
        item.controls.map(function (itm, key) {
          options.option[length - 1]["children"][key] = {
            id: itm._id,
            label: itm.name,
          };
        });
      });
      console.log(options);
      this.options = options;
    },
  },
};
</script>

<style lang="scss">
.dark-layout {
  .vue-treeselect {
    .vue-treeselect__control {
      background: #0e081c;
      border-color: #3b4253;

      &:hover {
        border-color: #5b38bd !important;
      }
      &:focus {
        border-color: #5b38bd;
      }

      .vue-treeselect__value-container {
        .vue-treeselect__multi-value {
          .vue-treeselect__multi-value-item-container {
            .vue-treeselect__multi-value-item {
              background-color: rgba(0, 207, 232, 0.12);
              color: #00cfe8 !important;

              .vue-treeselect__multi-value-label {
              }

              .vue-treeselect__value-remove {
                color: #ea5455 !important;
              }
            }
          }
        }
      }
    }

    .vue-treeselect__menu-container {
      .vue-treeselect__menu {
        background: #0e081c;
        color: #d0d2d6;
        border-color: #3b4253;

        .vue-treeselect__list {
          .vue-treeselect__list-item {
            .vue-treeselect__option {
            }

            .vue-treeselect__option--highlight {
              background: #5b38bd !important;
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
